// components/Filters.js
import React from 'react';
import { Grid } from '@mui/material';
import CustomSlider from '../../../components/form/slider';

const Filters = ({ beds, setBeds, baths, setBaths, priceRange, setPriceRange }) => {
    return (
        <>
            <Grid item lg={1} md={1} xs={3}>
                <CustomSlider min={0} max={5} label={'Bed'} value={beds} onChange={(e) => setBeds(e?.target?.value)} />
            </Grid>
            <div style={{ height: 45, width: 1, backgroundColor: '#fff' }} />
            <Grid item lg={1} md={1} xs={3}>
                <CustomSlider min={0} max={5} label={'Baths'} value={baths} onChange={(e) => setBaths(e?.target?.value)} />
            </Grid>
            <div style={{ height: 45, width: 1, backgroundColor: '#fff' }} />
            <Grid item lg={1.5} md={2} xs={5}>
                <CustomSlider
                    min={0}
                    max={10000}
                    label={'Price Range'}
                    value={priceRange}
                    onChange={(e) => setPriceRange(e?.target?.value)}
                />
            </Grid>
        </>
    );
};

export default Filters;
