// theme.js
import { createTheme } from '@mui/material/styles';
import { blue, green } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            main: '#37bfcb'
        },
        secondary: {
            main: green[500]
        }
    },
    typography: {
        fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
        button: {
            textTransform: 'capitalize'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Lato';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Lato'), local('Lato-Regular'),
                   url('/fonts/Lato-Regular.woff2') format('woff2');
            }
          `
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5, // Round corners
                    textTransform: 'none' // Disable uppercase text
                },
                containedPrimary: {
                    backgroundColor: '#19ABB5', // Custom background for primary button
                    color: '#fff', // Text color
                    '&:hover': {
                        backgroundColor: '#19ABB5cc' // Darker shade on hover
                    }
                }
                // containedSecondary: {
                //     backgroundColor: '#f50057', // Custom background for secondary button
                //     color: '#fff', // Text color
                //     '&:hover': {
                //         backgroundColor: '#c51162' // Darker shade on hover
                //     }
                // }
            }
        }
    }
});

export default theme;
