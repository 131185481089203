import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from '../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';

export default function AccountMenu({ handleClose, open, anchorEl }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { user } = useSelector((state) => state?.user);

    const handleLogout = () => {
        handleClose();
        localStorage.clear();
        dispatch(saveUserData(null));
        navigate('/');
    };

    return (
        <React.Fragment>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 300,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {user?.role[0] === '2101' && (
                    <>
                        <MenuItem
                            onClick={() => {
                                navigate('/t-account');
                                handleClose();
                            }}
                        >
                            <Avatar /> My Account
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/ld-properties');
                                handleClose();
                            }}
                        >
                            <Avatar /> My Properties
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/notifications');
                                handleClose();
                            }}
                        >
                            <Avatar /> Notifications
                        </MenuItem>
                        <Divider />
                    </>
                )}
                {user?.role[0] === '2109' && (
                    <>
                        <MenuItem
                            onClick={() => {
                                navigate('/t-account');
                                handleClose();
                            }}
                        >
                            <Avatar /> My Account
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/t-favorites');
                                handleClose();
                            }}
                        >
                            <Avatar /> My Favorites
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/t-bids');
                                handleClose();
                            }}
                        >
                            <Avatar /> My Bids
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/t-service');
                                handleClose();
                            }}
                        >
                            <Avatar /> My Services
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/t-rental');
                                handleClose();
                            }}
                        >
                            <Avatar /> My Rental
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/t-payrent');
                                handleClose();
                            }}
                        >
                            <Avatar /> Pay Rent
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/notifications');
                                handleClose();
                            }}
                        >
                            <Avatar /> Notifications
                        </MenuItem>
                        <Divider />
                    </>
                )}

                {user?.role[0] === '2000' && (
                    <>
                        <MenuItem
                            onClick={() => {
                                navigate('/tenant-list');
                                handleClose();
                            }}
                        >
                            <Avatar /> Tenants
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate('/landlord-list');
                                handleClose();
                            }}
                        >
                            <Avatar /> Landlords
                        </MenuItem>
                        <Divider />
                    </>
                )}

                {/* <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
