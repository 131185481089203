import { Box, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';
import { PROPERTY_URL } from '../../../utils/constants';
import { formatAmount, getTimeDifference, numberToWords } from '../../../utils/helpers';
import FavoriteIcon from '@mui/icons-material/Favorite';

function PropertyCard1({ onClick, title, image, currentBid, bedrooms, bathrooms, area, endDateTime, isFavorite, onClickFavorite }) {
    return (
        <Box
            onClick={onClick}
            sx={{
                border: '1px solid #d5d5d5',
                width: 302,
                cursor: 'pointer',
                transition: '0.3s ease-in-out',
                '&:hover': { borderColor: '#000' }
            }}
        >
            <div style={{ width: 300, height: 200, backgroundColor: '#d5d5d5', position: 'relative' }}>
                <img src={`${PROPERTY_URL}${image}`} alt={`${title}`} style={{ width: 300, height: 200 }} />

                <IconButton onClick={onClickFavorite} sx={{ position: 'absolute', top: 1, left: 1 }}>
                    <FavoriteIcon sx={{ color: isFavorite ? 'red' : 'gray', fontSize: 20 }} />
                </IconButton>
            </div>

            <Box sx={{ p: 2 }}>
                <Typography fontWeight={'bold'}>{title}</Typography>

                <Typography sx={{ fontSize: 12, textTransform: 'capitalize' }}>
                    {numberToWords(bedrooms)} Bedrooms | {numberToWords(bathrooms)} Bathrooms | {area} sq ft
                </Typography>

                <Divider sx={{ bgcolor: '#000', mt: 2 }} />
                <Typography sx={{ fontSize: 12, mt: 1 }}>
                    Current bid: ${formatAmount(currentBid)} | Time remaining: {getTimeDifference(endDateTime)}
                </Typography>
            </Box>
        </Box>
    );
}

export default PropertyCard1;
