import { Box, Divider, FormControl, Grid, InputAdornment, MenuItem, Pagination, Select, Typography } from '@mui/material';
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GOOGLE_MAP_KEY } from '../../utils/constants';
import PropertyCard2 from './comps/PropertyCard2';
import CircularLoader from '../../components/loader/CircularLoader';
import ErrorText from '../../components/error/ErrorText';
import isEmpty from '../../utils/helpers';

const center = {
    lat: -3.745,
    lng: -38.523
};

function LocationView({ data, error, isLoading, sortBy, setSortBy, itemPerPage, setItemPerPage, page, setPage, onClickFavorite }) {
    const navigate = useNavigate();

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_KEY
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        <Grid container height={'75.5vh'}>
            <Grid item bgcolor={'#fff'} lg={3}>
                <Box sx={{ bgcolor: '#e5e5e5', p: 1.5, px: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 14 }}>{data?.paginationMetadata?.totalCount || '0'} Results found</Typography>

                    <FormControl sx={{ flex: 1 }} size="small">
                        <Select
                            sx={{ fontSize: 14 }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Typography fontSize={14}>Sort by:</Typography>
                                </InputAdornment>
                            }
                            value={sortBy}
                            onChange={(e) => setSortBy(e?.target?.value)}
                        >
                            <MenuItem sx={{ fontSize: 14 }} value={'featured'}>
                                Featured
                            </MenuItem>
                            <MenuItem sx={{ fontSize: 14 }} value={'low-to-high'}>
                                Price - Low to High
                            </MenuItem>
                            <MenuItem sx={{ fontSize: 14 }} value={'high-to-low'}>
                                Price - High to Low
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ height: '67vh', overflow: 'auto' }}>
                    {isLoading && <CircularLoader sx={{ height: '50vh' }} />}
                    {!!error && <ErrorText error={error} sx={{ height: '50vh' }} />}

                    {!isEmpty(data?.items) &&
                        data?.items?.map((item) => (
                            <PropertyCard2
                                key={item._id}
                                title={item?.title}
                                bedrooms={item?.bedrooms}
                                bathrooms={item?.bathrooms}
                                area={item?.area}
                                image={item?.images[0]}
                                isFavorite={item?.isFavorite}
                                currentBid={item?.currentBid}
                                endDateTime={item?.endDateTime}
                                onClick={() => navigate(`/properties/${item?._id}`)}
                                onClickFavorite={(event) => onClickFavorite(event, item?._id, !item?.isFavorite)}
                            />
                        ))}

                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                        <Pagination
                            count={data?.paginationMetadata?.totalPages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={(e, value) => setPage(value)}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item bgcolor={'#f5f5f5'} lg={9}>
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={center}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        zoom={15}
                    >
                        {/* {property?.lat && <MarkerF position={{ lat: property?.lat, lng: property?.lng }} />} */}
                    </GoogleMap>
                )}
            </Grid>
        </Grid>
    );
}

export default LocationView;
