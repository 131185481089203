import React, { useState } from 'react';
import Header from '../header';

function Navbar({ children, show }) {
    const [sideBar, setSidebar] = useState(false);

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header show={show} setSidebar={setSidebar} sideBar={sideBar} />
            <div style={{flex: 1}}>{children}</div>
        </div>
    );
}

export default Navbar;
