import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import SimpleTextField from '../../../../components/form/Textfield/SimpleTextFIeld';
import * as Yup from 'yup';
import { customEmailValidation, formatPhoneNumber } from '../../../../utils/helpers';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import MaskedInput from '../../../../components/form/Textfield/MaskedInput';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import GradientButton from '../../../../components/form/button/GradientButton';
import { API_URL, AVATAR_URL } from '../../../../utils/constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveUserData } from '../../../../redux/slices/userSlice';

function Profile() {
    let { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [timeZone, setTimeZone] = useState(null);
    const [isAutoDetectTimeZone, setIsAutoDetectTimeZone] = useState(user?.isAutoDetectTimeZone);

    const FORM_VALIDATION = Yup.object().shape({
        userName: Yup.string().required('Required'),
        dateOfBirth: Yup.date().required('Required'),
        firstName: Yup.string().trim().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string()
            .required('Required')
            .test('is-valid-email', 'Email is invalid', (value) => customEmailValidation(value)),
        phoneNo: Yup.string().required('Required')
    });

    const hiddenFileInput = useRef(null);

    const handleClick = (event) => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        let image = {
            image: fileUploaded,
            url: URL.createObjectURL(fileUploaded)
        };

        setUploadedImage(image);
    };

    const submitProfileForm = async (values) => {
        setIsLoading(true);
        try {
            const autotimeZone = moment().format('Z');
            let formdata = new FormData();

            uploadedImage?.image && formdata.append('avatar', uploadedImage?.image, uploadedImage?.image?.name);
            formdata.append('firstName', values?.firstName);
            formdata.append('lastName', values?.lastName);
            formdata.append('email', values?.email);
            formdata.append('userName', values?.userName);
            formdata.append('timeZone', isAutoDetectTimeZone ? `UTC ${autotimeZone}` : `UTC ${timeZone}`);
            formdata.append('isAutoDetectTimeZone', isAutoDetectTimeZone);
            formdata.append('phoneNo', values?.phoneNo);
            formdata.append('dateOfBirth', values?.dateOfBirth);

            const resUpdateProfile = await axios.put(`${API_URL}users/${user?.userId}`, formdata, {
                headers: { Authorization: `Bearer ${user?.token}`, 'Content-Type': 'multipart/form-data;', Accept: 'application/json' }
            });

            if (resUpdateProfile) {
                dispatch(saveUserData({ ...user, ...resUpdateProfile?.data?.updated }));
                return toast.success(resUpdateProfile?.data?.message);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            initialValues={{
                userName: user?.userName,
                dateOfBirth: new Date(user?.dateOfBirth).toLocaleDateString('en-CA'),
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                phoneNo: user?.phoneNo
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => submitProfileForm(values)}
        >
            <Form>
                <Grid container>
                    <RowGrid
                        sx={{ mt: 2 }}
                        title="Pre-approval Status"
                        answer={user?.status == '1000' ? 'Processing' : user?.status == '1010' ? 'Rejected' : 'Approved'}
                        isEdit={false}
                    />

                    <RowGrid title="Account Type" answer={user?.role[0] === '2109' ? 'Tenant' : 'Landlord'} isEdit={false} />

                    <RowGrid
                        title="Name"
                        answer={user?.name}
                        field={
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <SimpleTextField name={'firstName'} />
                                <SimpleTextField name={'lastName'} />
                            </Box>
                        }
                    />

                    <RowGrid title="Username" answer={user?.userName} field={<SimpleTextField name={'userName'} />} />

                    <RowGrid
                        title="Date of Birth"
                        answer={moment(user?.dateOfBirth).format('MMMM DD, YYYY')}
                        field={
                            <SimpleTextField
                                type="date"
                                name={'dateOfBirth'}
                                InputProps={{
                                    inputProps: {
                                        max: new Date().toLocaleDateString('en-CA')
                                    }
                                }}
                            />
                        }
                    />

                    <RowGrid title="Email" answer={user?.email} field={<SimpleTextField name={'email'} />} />

                    <RowGrid
                        title="Phone Number"
                        answer={formatPhoneNumber(user?.phoneNo)}
                        field={<Field name="phoneNo" mask="(999) 999-9999" component={MaskedInput} />}
                    />

                    <RowGrid
                        isEdit={false}
                        defaultState={true}
                        title="Time Zone"
                        answer={''}
                        field={
                            <>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#444444'
                                                    }
                                                }}
                                                size="small"
                                                checked={isAutoDetectTimeZone}
                                                onChange={(e) => setIsAutoDetectTimeZone(e?.target?.checked)}
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize={13}
                                            >{`Automatically determine my time zone: ${user?.timeZone}`}</Typography>
                                        }
                                    />
                                </FormGroup>
                                <Button
                                    sx={{
                                        width: 200,
                                        ml: 1,
                                        mt: -1,
                                        textTransform: 'none',
                                        color: 'gray',
                                        fontSize: 13,
                                        fontWeight: '400',
                                        p: 0
                                    }}
                                >
                                    Select time zone manually
                                </Button>
                            </>
                        }
                    />

                    <RowGrid
                        isEdit={false}
                        defaultState={true}
                        title="Profile Image"
                        answer={''}
                        field={
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={uploadedImage ? uploadedImage?.url : `${AVATAR_URL}${user?.avatar}`}
                                    width={30}
                                    height={30}
                                    alt=""
                                    style={{ backgroundColor: 'gray', borderRadius: 20 }}
                                />
                                <button
                                    onClick={handleClick}
                                    style={{ padding: 5, backgroundColor: '#fff', border: '0px', cursor: 'pointer' }}
                                >
                                    Select Image
                                </button>
                                <input
                                    type="file"
                                    accept="image/*s"
                                    onChange={handleChange}
                                    ref={hiddenFileInput}
                                    style={{ display: 'none' }}
                                />
                            </Box>
                        }
                    />

                    <GradientButton loading={isLoading} type="submit" label="Save Changes" sx={{ mt: 2, px: 2 }} />
                </Grid>
            </Form>
        </Formik>
    );
}

export default Profile;

const RowGrid = ({ title, answer, field, isEdit = true, sx, defaultState }) => {
    const [isEditable, setIsEditable] = useState(defaultState);

    return (
        <Grid item lg={12} xs={12} container sx={{ borderBottom: '1px solid #363636', display: 'flex', alignItems: 'center', ...sx }}>
            <Grid item lg={2} xs={10} sx={{ py: 1.7 }}>
                <Typography sx={{ fontSize: 14 }}>{title}</Typography>
            </Grid>

            <Grid item lg={2} sx={{ display: { lg: 'none', xs: 'block' } }}>
                {isEdit && (
                    <IconButton
                        size={'small'}
                        sx={{ float: 'right' }}
                        color="inherit"
                        title="Edit"
                        onClick={() => setIsEditable((pre) => !pre)}
                    >
                        {isEditable ? (
                            <CloseIcon sx={{ fontSize: 18, color: 'gray' }} />
                        ) : (
                            <EditIcon sx={{ fontSize: 18, color: 'gray' }} />
                        )}
                    </IconButton>
                )}
            </Grid>

            <Grid item lg={5} xs={12}>
                {isEditable ? field : <Typography sx={{ fontSize: 14, textTransform: 'none' }}>{answer}</Typography>}
            </Grid>

            <Grid item lg={5} sx={{ display: { lg: 'block', xs: 'none' } }}>
                {isEdit && (
                    <IconButton
                        size={'small'}
                        sx={{ float: 'right' }}
                        color="inherit"
                        title="Edit"
                        onClick={() => setIsEditable((pre) => !pre)}
                    >
                        {isEditable ? (
                            <CloseIcon sx={{ fontSize: 18, color: 'gray' }} />
                        ) : (
                            <EditIcon sx={{ fontSize: 18, color: 'gray' }} />
                        )}
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};
