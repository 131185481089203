import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PROPERTY_URL } from '../../../utils/constants';
import { formatAmount, getTimeDifference, numberToWords } from '../../../utils/helpers';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

function PropertyCard2({ onClick, title, image, currentBid, bedrooms, bathrooms, area, isFavorite, endDateTime, onClickFavorite }) {
    const navigate = useNavigate();

    const [showFavoriteBox, setShowFavoriteBox] = useState(false);

    const handleFav = (e) => {
        onClickFavorite(e);
        if (!isFavorite) {
            setShowFavoriteBox(true);
        }
    };

    const styles = {
        container: {
            border: '1px solid #fff',
            borderBottom: '1px solid #444444',
            cursor: 'pointer',
            padding: 2,
            alignItems: 'center',
            transition: '0.3s ease-in-out',
            display: 'flex',
            '&:hover': { border: '1px solid #0c3455' }
        },
        btn: { border: 0, backgroundColor: '#0c3455', color: '#fff', fontSize: 11, marginLeft: 5, cursor: 'pointer' }
    };

    return (
        <Box onClick={onClick} sx={styles.container}>
            <div style={{ width: '100px', height: '100px', backgroundColor: '#d5d5d5', position: 'relative' }}>
                <img src={`${PROPERTY_URL}${image}`} alt={`${title}`} style={{ width: 100, height: 100 }} />

                <IconButton onClick={handleFav} sx={{ position: 'absolute', top: 1, left: 1 }}>
                    <FavoriteIcon sx={{ color: isFavorite ? 'red' : 'gray', fontSize: 20 }} />
                </IconButton>
            </div>

            {showFavoriteBox ? (
                <Box sx={{ px: 2, py: 1, flex: 1, height: '100px', bgcolor: '#0c3455', position: 'relative' }}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowFavoriteBox(false);
                        }}
                        sx={{ position: 'absolute', top: 1, right: 1 }}
                    >
                        <CloseIcon sx={{ color: '#fff', fontSize: 20 }} />
                    </IconButton>

                    <Typography fontWeight={'bold'} color={'#fff'}>
                        Added to your favorites!
                    </Typography>

                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate('/t-favorites');
                        }}
                        sx={{ p: 0 }}
                    >
                        <Typography sx={{ fontSize: 11, color: '#fff' }}>View all of your favorites</Typography>
                    </Button>

                    <Divider sx={{ bgcolor: '#fff', my: 1, mt: 1.5 }} />
                    <div style={{ display: 'flex' }}>
                        <Typography sx={{ fontSize: 11, color: '#fff' }}>Update your notification Settings</Typography>
                        <button
                            style={styles.btn}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate('/t-account');
                            }}
                        >
                            <b>here</b>
                        </button>
                    </div>
                </Box>
            ) : (
                <Box sx={{ pl: 2, flex: 1 }}>
                    <Typography fontWeight={'bold'}>{title}</Typography>

                    <Typography sx={{ fontSize: 12, textTransform: 'capitalize' }}>
                        {numberToWords(bedrooms)} Bed | {numberToWords(bathrooms)} Bath | {area} sq ft
                    </Typography>

                    <Divider sx={{ bgcolor: '#000', my: 1 }} />
                    <Typography sx={{ fontSize: 12 }}>Current bid: ${formatAmount(currentBid)}</Typography>
                    <Typography sx={{ fontSize: 12 }}>Time remaining: {getTimeDifference(endDateTime)}</Typography>
                </Box>
            )}
        </Box>
    );
}

export default PropertyCard2;
