import React, { useState } from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { Form, Formik } from 'formik';
import { Box, Button, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import SimpleTextField from '../../../../components/form/Textfield/SimpleTextFIeld';
import * as Yup from 'yup';
import { API_URL, COLORS } from '../../../../utils/constants';
import { useSelector } from 'react-redux';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoCompleteChip from '../../../../components/form/AutoComplete/AutoCompleteChip';
import LocationPicker from './LocationPicker';
import ImageUpload from '../../../../components/form/button/ImageUpload';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GradientButton from '../../../../components/form/button/GradientButton';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const amenitiesList = [
    'Gym/fitness centre',
    'Air conditioning',
    'Furnished',
    'Laundry',
    'Parking',
    'Dishwasher',
    'Basement',
    'Front desk',
    'Party room',
    'Natural gas',
    'Pets',
    'Pool',
    'Balcony',
    'Fireplace',
    'Storage locker',
    'Lounge',
    'Theatre room',
    'Games room'
];

const INITIAL_VALUES = {
    title: '',
    description: '',
    bedrooms: null,
    bathrooms: null,
    area: null,
    amenities: [],
    utilsByTenant: [],
    locationAnalysis: '',
    startDateTime: '',
    endDateTime: '',
    startingBid: '',
    maximumBid: '',
    unitAvailableDate: '',
    country: '',
    state: '',
    city: '',
    zipCode: ''
};

const FORM_VALIDATION = Yup.object().shape({
    title: Yup.string().trim().required('Required'),
    bedrooms: Yup.number().positive('Must be in positive').max(20, 'The number cannot be greater than 20').required('Required'),
    bathrooms: Yup.number().positive('Must be in positive').max(20, 'The number cannot be greater than 20').required('Required'),
    area: Yup.number().positive('Must be in positive').required('Required'),
    amenities: Yup.array().min(3, 'Add a minimum of 3 amenities').required('Required'),
    utilsByTenant: Yup.array().min(1, 'Add a minimum of 1').required('Required'),
    description: Yup.string().trim().required('Required'),
    locationAnalysis: Yup.string().trim().required('Required'),
    startDateTime: Yup.date()
        .min(Yup.ref('endDateTime'), 'Start date time must be less than end date time')
        .min(new Date(), 'Start date time must be after current date time')
        .required('Required'),
    endDateTime: Yup.date().min(Yup.ref('startDateTime'), 'End date time must be greater than start date time').required('Required'),
    startingBid: Yup.number().typeError('Amount must be a number').required('Required'),
    maximumBid: Yup.number()
        .typeError('Amount must be a number')
        .min(Yup.ref('startingBid'), 'Maximun bid should be greater then start Bid')
        .required('Required'),
    unitAvailableDate: Yup.date()
        .min(Yup.ref('endDateTime'), 'Unit available date time must be greater than start date time')
        .required('Required'),
    country: Yup.string().trim().required('Required'),
    state: Yup.string().trim().required('Required'),
    city: Yup.string().trim().required('Required'),
    zipCode: Yup.string().trim().required('Required')
});

function AddProperty() {
    const navigate = useNavigate();
    let { user } = useSelector((state) => state?.user);
    const [location, setLocation] = useState(null);

    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const createProperty = async (values) => {
        setIsLoading(true);
        try {
            if (images?.length < 3) {
                return toast.error('Please upload atleast 3 images');
            }
            if (location < 3) {
                return toast.error('Please select location of property');
            }

            let formdata = new FormData();

            for (let i = 0; i < images.length; i++) {
                formdata.append('images', images[i]);
            }
            for (let i = 0; i < values?.amenities.length; i++) {
                formdata.append('amenities', values?.amenities[i]);
            }
            for (let i = 0; i < values?.utilsByTenant.length; i++) {
                formdata.append('utilsByTenant', values?.utilsByTenant[i]);
            }
            formdata.append('user', user?.userId);
            formdata.append('title', values?.title);
            formdata.append('description', values?.description);
            formdata.append('bedrooms', values?.bedrooms);
            formdata.append('bathrooms', values?.bathrooms);
            formdata.append('area', values?.area);
            formdata.append('locationAnalysis', values?.locationAnalysis);
            formdata.append('startDateTime', values?.startDateTime);
            formdata.append('endDateTime', values?.endDateTime);
            formdata.append('startingBid', values?.startingBid);
            formdata.append('maximumBid', values?.maximumBid);
            formdata.append('currentBid', values?.startingBid);
            formdata.append('isActive', true);
            formdata.append('unitAvailableDate', values?.unitAvailableDate);
            formdata.append('lat', location?.lat);
            formdata.append('lng', location?.lng);
            formdata.append('country', values?.country);
            formdata.append('state', values?.state);
            formdata.append('city', values?.city);
            formdata.append('zipCode', values?.zipCode);

            const res = await axios.post(`${API_URL}properties`, formdata, {
                headers: { Authorization: `Bearer ${user?.token}`, 'Content-Type': 'multipart/form-data;', Accept: 'application/json' }
            });

            if (res?.data) {
                navigate(-1);
                return toast.success('Property created successfully!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <SideMenu>
            <Box sx={{ padding: '2.5rem' }}>
                {user?.status !== '1020' && (
                    <Box sx={{ px: 4, py: 2, bgcolor: '#f5f5f5', borderRadius: 1, mb: 1 }}>
                        When your account status is approved then you can add new properties
                    </Box>
                )}

                <Typography variant="h5" fontWeight={'bold'} color={COLORS.primary}>
                    Add Property
                </Typography>

                <Formik
                    initialValues={INITIAL_VALUES}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, { resetForm }) => createProperty(values)}
                >
                    <Form>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid container spacing={2} item lg={8}>
                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold', mb: 2 }}>Property Information</Typography>
                                    <SimpleTextField label="Add Property Title or Name" name="title" />
                                </Grid>

                                <Grid container spacing={2} item lg={12} sm={12} xs={12}>
                                    <Grid item lg={4} sm={6} xs={12}>
                                        <SimpleTextField type="number" label="Number of Bedrooms" name="bedrooms" />
                                    </Grid>

                                    <Grid item lg={4} sm={6} xs={12}>
                                        <SimpleTextField type="number" label="Number of Bathrooms" name="bathrooms" />
                                    </Grid>

                                    <Grid item lg={4} sm={6} xs={12}>
                                        <SimpleTextField
                                            type="number"
                                            label="Property Area"
                                            name="area"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Typography fontSize={'14'}>Sq. ft.</Typography>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} item lg={12} sm={12} xs={12}>
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <SimpleTextField label="Country" name="country" />
                                    </Grid>

                                    <Grid item lg={3} sm={6} xs={12}>
                                        <SimpleTextField label="State" name="state" />
                                    </Grid>

                                    <Grid item lg={3} sm={6} xs={12}>
                                        <SimpleTextField label="City" name="city" />
                                    </Grid>

                                    <Grid item lg={3} sm={6} xs={12}>
                                        <SimpleTextField label="Zip Code" name="zipCode" />
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <AutoCompleteChip name="amenities" label="Unit Amenities" list={amenitiesList} />
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <AutoCompleteChip
                                        name="utilsByTenant"
                                        label="Utilities paid by tenant"
                                        list={['Water', 'Gas', 'Electricity']}
                                    />
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        {/* {location && (
                                            <> */}
                                        <SimpleTextField
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={location?.lat}
                                            label="Lat"
                                            name="lat"
                                        />
                                        <SimpleTextField
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={location?.lng}
                                            label="Long"
                                            name="lng"
                                        />
                                        {/* </>
                                        )}
                                        <Button
                                            fullWidth
                                            onClick={handleOpenLocation}
                                            sx={{ color: '#000', backgroundColor: 'lightgray', borderRadius: 2, p: 1 }}
                                        >
                                            Add Location
                                        </Button> */}
                                    </div>
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <SimpleTextField
                                        label="Unit Available Date"
                                        type="date"
                                        name={'unitAvailableDate'}
                                        InputProps={{
                                            inputProps: {
                                                min: new Date().toLocaleDateString('en-CA')
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <SimpleTextField placeholder="Add Property Description" name="description" multiline rows={4} />
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <SimpleTextField
                                        placeholder="Add Property Location Analysis"
                                        name="locationAnalysis"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>

                                <Grid container spacing={2} item lg={12} sm={12} xs={12}>
                                    <Grid item lg={12}>
                                        <Typography sx={{ fontSize: 16, fontWeight: 'bold', mt: 2 }}>Bid Information</Typography>
                                    </Grid>
                                    <Grid item lg={2.5} sm={6} xs={12}>
                                        <SimpleTextField
                                            type="number"
                                            label="Starting Bid"
                                            name="startingBid"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AttachMoneyIcon sx={{ fontSize: 18 }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={2.5} sm={6} xs={12}>
                                        <SimpleTextField
                                            type="number"
                                            label="Maximum Bid"
                                            name="maximumBid"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AttachMoneyIcon sx={{ fontSize: 18 }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={3.5} sm={6} xs={12}>
                                        <SimpleTextField
                                            label="Start DateTime"
                                            type="datetime-local"
                                            name={'startDateTime'}
                                            InputProps={{
                                                inputProps: {
                                                    max: new Date().toLocaleDateString('en-CA')
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={3.5} sm={6} xs={12}>
                                        <SimpleTextField
                                            label="Bid End DateTime"
                                            type="datetime-local"
                                            name={'endDateTime'}
                                            InputProps={{
                                                inputProps: {
                                                    max: new Date().toLocaleDateString('en-CA')
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Divider sx={{ bgcolor: COLORS.primary, mb: 2.5 }} />

                                    {user?.status === '1020' && (
                                        <GradientButton loading={isLoading} type="submit" label="Create Property" sx={{ px: 2 }} />
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container item lg={4}>
                                <Grid item lg={12} xs={12}>
                                    <ImageUpload images={images} setImages={setImages} />
                                </Grid>
                                <Grid item lg={12} xs={12} mt={2}>
                                    <LocationPicker setLocation={setLocation} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
        </SideMenu>
    );
}

export default AddProperty;
