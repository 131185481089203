import React from 'react';
import Navbar from '../../layout/Navbar';
import { Box, Button, Grid, Typography } from '@mui/material';
import { COLORS } from '../../utils/constants';
import About1 from '../../assets/images/About-1.webp';
import About2 from '../../assets/images/About-2.webp';
import About3 from '../../assets/images/About-3.webp';
import About4 from '../../assets/images/About-4.webp';
import About6 from '../../assets/images/About-6.webp';
import Logo from '../../assets/images/fav-icon.png';
import './style.css';
import LandingPageFooter from '../../layout/footer/LandingPageFooter';

import Picture1 from '../../assets/images/Picture1.webp';
import Picture2 from '../../assets/images/Picture2.webp';
import Picture3 from '../../assets/images/Picture3.webp';
import Picture4 from '../../assets/images/Picture4.webp';
import Picture5 from '../../assets/images/Picture5.webp';
import Picture6 from '../../assets/images/Picture6.webp';
import Picture7 from '../../assets/images/Picture7.webp';

const aboutUSData = [
    {
        image: Picture7,
        title: 'About PerfectPayRentBid',
        info: "At PerfectPayRentBid, we're on a mission to revolutionize the renting experience for both landlords and tenants. We believe that renting a property should be as empowering and rewarding as owning one, and we're dedicated to making that vision a reality."
    },
    {
        image: Picture6,
        title: 'Empowering Tenants, Supporting Landlords',
        info: 'Our platform empowers Tenants by removing traditional barriers and hassles. For landlords, we offer peace of mind and security through guaranteed rent and transparent practices.'
    },
    {
        image: Picture5,
        title: 'Transparency and Trust',
        info: 'Transparency and trust are at the core of everything we do. From fair practices to open communication, we strive to create a platform where everyone feels valued and respected.'
    },
    {
        image: Picture4,
        title: 'Innovation and Excellence',
        info: "We're committed to innovation and excellence in everything we do. From our cutting-edge bidding platform to our comprehensive rent assistance options, we're constantly pushing the boundaries to provide the best possible experience for our users. We're dedicated to staying ahead of the curve and adapting to the evolving needs of the rental market."
    },
    {
        image: Picture3,
        title: 'Join Us in Redefining Renting',
        info: "Whether you're a landlord looking for a hassle-free renting experience or a tenant searching for the perfect home, we invite you to join us in redefining renting. With PerfectPayRentBid, renting has never been easier, more transparent, or more empowering. Welcome to the future of renting. Welcome to PerfectPayRentBid."
    },
    {
        image: Picture2,
        title: 'Vision Statement:',
        info: 'Our vision is to transform the rental experience, making it as enriching as homeownership. We aspire to create a rental ecosystem that offers security, flexibility, and satisfaction to both tenants and landlords.'
    },
    {
        image: Picture1,
        title: 'Mission Statement:',
        info: 'Our mission is to empower Tenants and landlords alike through seamless, transparent, and fair rental solutions. By leveraging technology and prioritizing integrity, we aim to redefine the rental industry, ensuring positive outcomes for all stakeholders involved.'
    }
];

function AboutUs() {
    return (
        <Navbar>
            {aboutUSData?.map((item, index) => (
                <div key={index} className="get-to-know">
                    <div className="set-box-about container">
                        <p style={{ fontSize: 24, fontWeight: '700', color: '#2F3C45' }}>{item?.title}</p>
                        <p style={{ fontSize: 16, fontWeight: '400', color: '#2F3C45', lineHeight: 1.4 }}>{item?.info}</p>

                        <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={item?.image} style={{ height: 420, borderRadius: '20px' }} />
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="get-to-know">
                <div className="set-box-about container">
                    <div className="left-side">
                        <p className="upper-text">GET TO KNOW US</p>
                        <p className="title">About Us</p>
                        <p className="text">
                            At PerfectPayRentBid, we're on a mission to revolutionize the renting experience for both landlords and tenants.
                            We believe that renting a property should be as empowering and rewarding as owning one, and we're dedicated to
                            making that vision a reality.
                        </p>
                    </div>

                    <div className="right-side">
                        <div className="image-container">
                            <img src={About1} style={{ width: '47%', borderRadius: '20px', marginTop: -20 }} />
                            <img src={About2} style={{ width: '47%', borderRadius: '20px', marginBottom: -20 }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="empowering-tenants">
                <div className="set-box-about container">
                    <div className="empowering-tenants-left">
                        <div className="image-container">
                            <img src={About3} style={{ width: '47%', borderRadius: '20px', marginTop: -20 }} />
                            <img src={About4} style={{ width: '47%', borderRadius: '20px', marginBottom: -20 }} />
                        </div>
                    </div>

                    <div className="empowering-tenants-right">
                        <p className="title">Empowering Tenants, Supporting Landlords</p>
                        <Typography sx={{ mt: 2 }}>
                            Our platform empowers Tenants by removing traditional barriers and hassles. For landlords, we offer peace of
                            mind and security through guaranteed rent and transparent practices.
                        </Typography>

                        <Box sx={{ mt: 3, p: 2, borderLeft: `2px solid ${COLORS.secondory}` }}>
                            <Typography variant="subtitle2">Transparency and Trust</Typography>
                            <Typography sx={{ fontSize: 15 }}>
                                Transparency and trust are at the core of everything we do. From fair practices to open communication, we
                                strive to create a platform where everyone feels valued and respected.
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 3, px: 2, py: 1, borderLeft: `2px solid ${COLORS.secondory}` }}>
                            <Typography variant="subtitle2">Innovation and Excellence</Typography>
                            <Typography sx={{ fontSize: 15 }}>
                                We're committed to innovation and excellence in everything we do. From our cutting-edge bidding platform to
                                our comprehensive rent assistance options, we're constantly pushing the boundaries to provide the best
                                possible experience for our users. We're dedicated to staying ahead of the curve and adapting to the
                                evolving needs of the rental market.
                            </Typography>
                        </Box>
                    </div>
                </div>
            </div>

            <div className="excellence">
                <div className="set-box-about container">
                    <div className="left-side">
                        <p className="title">
                            Excellence in
                            <br />
                            Redefining Renting
                        </p>
                        <p className="text">
                            Whether you're a landlord looking for a hassle-free renting experience or a tenant searching for the perfect
                            home, we invite you to join us in redefining renting. With PerfectPayRentBid, renting has never been easier,
                            more transparent, or more empowering. Welcome to the future of renting. Welcome to PerfectPayRentBid.
                        </p>
                    </div>

                    <div className="excellence-right">
                        <div className="excellence-img-bg">
                            <Box sx={{}}>
                                <img src={Logo} style={{ width: 80, height: 80, objectFit: 'contain' }} />
                                <Typography variant="h4" fontWeight={'600'} my={5}>
                                    Most Trusted <br /> Property <br /> Consultants!
                                </Typography>
                                <Button variant="contained" sx={{ textTransform: 'capitalize', width: 150, bgcolor: COLORS.secondory }}>
                                    Join Us
                                </Button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>

            <div className="empowering-tenants">
                <div className="set-box-about container">
                    <div className="empowering-tenants-left">
                        <div className="image-container">
                            <img src={About6} style={{ width: '90%', borderRadius: '20px' }} />
                        </div>
                    </div>

                    <div className="empowering-tenants-right">
                        <div>
                            <p className="title">Vision</p>
                            <p className="text">
                                Our vision is to transform the rental experience, making it as enriching as homeownership. We aspire to
                                create a rental ecosystem that offers security, flexibility, and satisfaction to both tenants and landlords.
                            </p>
                        </div>

                        <div>
                            <p className="title">Mission</p>
                            <p className="text">
                                Our mission is to empower Tenants and landlords alike through seamless, transparent, and fair rental
                                solutions. By leveraging technology and prioritizing integrity, we aim to redefine the rental industry,
                                ensuring positive outcomes for all stakeholders involved.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            <LandingPageFooter />
        </Navbar>
    );
}

export default AboutUs;
