import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { AVATAR_URL } from '../../../../utils/constants';
import CircularLoader from '../../../../components/loader/CircularLoader';
import ErrorText from '../../../../components/error/ErrorText';
import { formatAmount, formatPhoneNumber } from '../../../../utils/helpers';
import moment from 'moment';
import useFetch from '../../../../hooks/useFetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRequest } from '../../../../utils/api';
import ModalConfirmation from '../../../../components/modal/ModalConfirmation';

function LandlordDetail() {
    const { id } = useParams();
    const { data, isLoading, error, refetch } = useFetch(`users/landlord/detail/${id}`);

    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const updateStatus = async () => {
        setLoading(true);
        try {
            const res = await apiRequest('put', `users/update-status/${id}`, { status });
            if (res?.data) {
                await refetch();
                setOpenConfirmation(false);
                return toast.success('Status updated!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SideMenu>
            {isLoading && <CircularLoader />}
            {error && <ErrorText error={error} />}

            {data && (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, alignItems: 'center' }}>
                        <img
                            alt="profile pic"
                            src={`${AVATAR_URL}${data?.profile?.avatar}`}
                            style={{ width: 90, height: 90, borderRadius: 45, backgroundColor: '#e5e5e5' }}
                        />

                        <Box>
                            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                                <InputLabel id="demo-select-small-label">Status</InputLabel>
                                <Select
                                    defaultValue={data?.profile?.status}
                                    // value={status}
                                    label="Status"
                                    onChange={(e) => setStatus(e?.target?.value)}
                                >
                                    <MenuItem value={'1000'}>Processing</MenuItem>
                                    <MenuItem value={'1010'}>Rejected</MenuItem>
                                    <MenuItem value={'1020'}>Approved</MenuItem>
                                </Select>
                            </FormControl>

                            <Button onClick={() => setOpenConfirmation(true)} sx={{ mt: 1 }} variant="contained">
                                Update Status
                            </Button>
                        </Box>
                    </Box>

                    <Grid container spacing={2} p={3}>
                        <Grid item lg={12} mb={3}>
                            <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 1 }}>Profile Information</Typography>
                            <Box bgcolor={'#f5f5f5'} borderRadius={2} p={2}>
                                <Grid container spacing={1}>
                                    <Grid item lg={3}>
                                        First Name
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.firstName}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Last Name
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.lastName}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Email
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.email}
                                    </Grid>
                                    <Grid item lg={3}>
                                        User Name
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.userName}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Phone Number
                                    </Grid>

                                    <Grid item lg={3}>
                                        {formatPhoneNumber(data?.profile?.phoneNo)}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Date of Birth
                                    </Grid>
                                    <Grid item lg={3}>
                                        {moment(data?.profile?.dateOfBirth).format('MMM DD, YYYY')}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Role
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.role === '2101' && 'Landlord'}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Status
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.status === '1000'
                                            ? 'Processing'
                                            : data?.profile?.status === '1010'
                                            ? 'Rejected'
                                            : 'Approved'}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Email Verified
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.isVerifiedEmail ? 'Yes' : 'No'}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Time Zone
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.timeZone}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Created On
                                    </Grid>
                                    <Grid item lg={3}>
                                        {moment(data?.profile?.createdOn).format('MMM DD, YYYY')}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}

            <ModalConfirmation
                loading={loading}
                open={openConfirmation}
                header={'Update Status'}
                description={'Are you sure you want to update status of this account?'}
                onClickNo={() => setOpenConfirmation(false)}
                onClickYes={() => updateStatus()}
            />
        </SideMenu>
    );
}

export default LandlordDetail;
