import React from 'react';
import './customToast.css';

function NotificationToast({ title, message, icon }) {
    return (
        <div className="custom-toast">
            {icon && <img src={icon} alt="icon" className="custom-toast-icon" />}
            <div className="custom-toast-content">
                <h4 className="custom-toast-title">{title}</h4>
                <p className="custom-toast-message">{message}</p>
            </div>
        </div>
    );
}

export default NotificationToast;
