import { COLORS } from '../../../utils/constants';
import React from 'react';
import { useField } from 'formik';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';

const CustomCheckbox = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...field}
                    checked={field.value}
                    size="small"
                    sx={{
                        '&.Mui-checked': {
                            color: '#444444'
                        }
                    }}
                />
            }
            label={
                <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                    {label}
                </Typography>
            }
        />
    );
};

export default CustomCheckbox;
