import { Box, Typography } from '@mui/material';
import React from 'react';

function ScoreBox() {
    return (
        <Box sx={{ border: '1.5px solid #eaedef', bgcolor: '#f7f8f9', display: 'flex', p: 3, my: 4 }}>
            <Box sx={{ width: '33%' }}>
                <Typography sx={{ fontSize: 13, textAlign: 'center' }}>Walk Score</Typography>
                <Typography sx={{ fontSize: 35, fontWeight: 'bold', color: '#0098d9', textAlign: 'center' }}>
                    96<span style={{ color: '#000', fontSize: 24, fontWeight: 400 }}>/100</span>
                </Typography>
            </Box>
            <div style={{ height: 70, width: '1.5px', backgroundColor: '#eaedef' }} />
            <Box sx={{ width: '33%' }}>
                <Typography sx={{ fontSize: 13, textAlign: 'center' }}>Walk Score</Typography>
                <Typography sx={{ fontSize: 35, fontWeight: 'bold', color: '#0098d9', textAlign: 'center' }}>
                    96<span style={{ color: '#000', fontSize: 24, fontWeight: 400 }}>/100</span>
                </Typography>
            </Box>
            <div style={{ height: 70, width: '1.5px', backgroundColor: '#eaedef' }} />
            <Box sx={{ width: '33%' }}>
                <Typography sx={{ fontSize: 13, textAlign: 'center' }}>Walk Score</Typography>
                <Typography sx={{ fontSize: 35, fontWeight: 'bold', color: '#0098d9', textAlign: 'center' }}>
                    96<span style={{ color: '#000', fontSize: 24, fontWeight: 400 }}>/100</span>
                </Typography>
            </Box>
        </Box>
    );
}

export default ScoreBox;
