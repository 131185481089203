// components/SearchBar.js
import React from 'react';
import { Grid, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const SearchBar = ({ searchQuery, setSearchQuery }) => {
    return (
        <Grid item lg={3} md={4} xs={12}>
            <TextField
                value={searchQuery}
                onChange={(e) => setSearchQuery(e?.target?.value)}
                variant="standard"
                fullWidth
                size="small"
                placeholder="Search | Street, City, State, Country, Zip Code"
                InputProps={{
                    style: { backgroundColor: '#fff', padding: 12, paddingBottom: 5, paddingLeft: 15, fontSize: 14 },
                    endAdornment: (
                        <InputAdornment position="end" sx={{ mt: -0.7 }}>
                            {searchQuery ? (
                                <IconButton onClick={() => setSearchQuery('')} size="small">
                                    <CloseIcon sx={{ fontSize: 25, color: '#444444' }} />
                                </IconButton>
                            ) : (
                                <SearchIcon sx={{ fontSize: 25, color: '#d5d5d5' }} />
                            )}
                        </InputAdornment>
                    )
                }}
            />
        </Grid>
    );
};

export default SearchBar;
