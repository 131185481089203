import React, { useState } from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/constants';
import useFetch from '../../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../../utils/helpers';
import Countdown from '../../../../components/CountDown';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRequest } from '../../../../utils/api';
import ErrorText from '../../../../components/error/ErrorText';
import CircularLoader from '../../../../components/loader/CircularLoader';
import ModalConfirmation from '../../../../components/modal/ModalConfirmation';
import { useNavigate } from 'react-router-dom';

function LProperties() {
    const { user } = useSelector((state) => state?.user);
    const navigate = useNavigate();
    const { data, isLoading, error, refetch: refetchBid } = useFetch(`properties/landlord/${user?.userId}`);

    const [open, setOpen] = useState({ type: '', id: null });
    const [loading, setLoading] = useState(false);

    return (
        <SideMenu>
            <Box sx={{ padding: '2.5rem', overflowY: 'auto' }}>
                {user?.status !== '1020' && (
                    <Box sx={{ px: 4, py: 2, bgcolor: '#f5f5f5', borderRadius: 1, mb: 1 }}>
                        When your account status is approved then you can add new properties
                    </Box>
                )}

                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h5" fontWeight={'bold'} color={COLORS.primary}>
                        My Properties
                    </Typography>

                    <Button disabled={user?.status !== '1020'} title="Hello" onClick={() => navigate('/add-property')} variant="contained">
                        Add New
                    </Button>
                </Box>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                <TableCell>Bidding Status</TableCell>
                                <TableCell>Guranteed Rent</TableCell>
                                <TableCell>Tenant</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <CircularLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {error && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <ErrorText error={error} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!!data &&
                                data?.items?.map((row) => (
                                    <TableRow
                                        key={row?._id}
                                        sx={{ borderBottom: '1px solid black', cursor: 'pointer', '&:hover': { bgcolor: '#f5f5f5' } }}
                                        onClick={() => navigate(`/properties/${row?._id}`)}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row?.title}
                                        </TableCell>
                                        <TableCell>{row?.status}</TableCell>
                                        <TableCell>${formatAmount(row?.maximumBid || 0)}</TableCell>
                                        <TableCell>{row?.tenant ? `${row?.tenant?.firstName} ${row?.tenant?.lastName}` : '—'}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </SideMenu>
    );
}

export default LProperties;
