import { useEffect, useState } from 'react';
import { apiRequest } from '../utils/api';

function useFetch(url) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const refetch = async () => {
        try {
            const response = await apiRequest('get', url);
            setData(response?.data);
        } catch (error) {
            setData(null);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        refetch();

        return () => {
            setData(null);
            setIsLoading(true);
            setError(null);
        };
    }, [url]);

    return { data, isLoading, error, refetch };
}

export default useFetch;
