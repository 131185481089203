import { AppBar, Avatar, Badge, Box, Button, Divider, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import './style.css';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import AccountMenu from './AccountMenu';

const Header = ({ show, setSidebar, sideBar }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    let { user } = useSelector((state) => state?.user);

    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((pre) => !pre);
    };

    //--------------------  For Account Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const drawer = (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                <img
                    src={Logo}
                    style={{ height: 30 }}
                    onClick={() => {
                        window.open('http://perfectpayrentbid.com');
                    }}
                />
            </Box>

            <Divider />

            <IconButton sx={{ mt: 3 }} onClick={handleDrawerToggle}>
                <CloseIcon sx={{ fontSize: 40 }} />
            </IconButton>

            <ul className="mobile-navigation">
                <li onClick={() => navigate('/')}>
                    <a>Home</a>
                </li>
                <li onClick={() => navigate('/about-us')}>
                    <a>About Us</a>
                </li>
                <li onClick={() => navigate('/for-landlords')}>
                    <a>For Landlords</a>
                </li>
                <li onClick={() => navigate('/for-tenants')}>
                    <a>For Tenants</a>
                </li>
                <li onClick={() => navigate('/faq')}>
                    <a>FAQ's</a>
                </li>
                {user?.isLogin ? (
                    <></>
                ) : (
                    <>
                        <div
                            style={{
                                height: 1.5,
                                width: '100%',
                                backgroundColor: '#d5d5d5'
                            }}
                        />
                        <li>
                            <a onClick={() => navigate('/register')}>Register</a>
                        </li>
                        <li>
                            <a onClick={() => navigate('/login')}>Sign In</a>
                        </li>
                    </>
                )}
            </ul>
        </Box>
    );

    return (
        <Box>
            <Box sx={{ backgroundColor: '#fff', py: 1, borderBottom: '2px solid black' }}>
                <div className="container">
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <IconButton
                            onClick={handleDrawerToggle}
                            aria-label="open drawer"
                            edge="start"
                            sx={{ mr: 2, display: { lg: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Box sx={{ display: 'flex', height: { xs: 20, lg: 30, md: 30, sm: 30 } }}>
                            <img
                                src={Logo}
                                style={{ height: '100%' }}
                                onClick={() => {
                                    window.open('http://perfectpayrentbid.com');
                                }}
                            />
                        </Box>

                        <Box sx={{ display: { lg: 'none' } }}>
                            {user?.isLogin && (
                                <>
                                    <Box onClick={handleClick} sx={{ display: 'flex', alignItems: 'center', ml: 3, cursor: 'pointer' }}>
                                        {/* <Typography sx={{ fontSize: 14, mr: 1 }}>{user?.name?.split(' ')[0]}</Typography> */}
                                        <Badge badgeContent={0} color="primary">
                                            <Avatar sx={{ width: 35, height: 35 }} alt={user?.name} src="/static/images/avatar/1.webp" />
                                        </Badge>
                                    </Box>
                                    <AccountMenu handleClose={handleClose} open={open} anchorEl={anchorEl} />
                                </>
                            )}
                        </Box>

                        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                            <ul className="navigaion-menu">
                                <li onClick={() => navigate('/')}>
                                    <a>Home</a>
                                </li>
                                <li onClick={() => navigate('/about-us')}>
                                    <a>About Us</a>
                                </li>
                                <li onClick={() => navigate('/for-landlords')}>
                                    <a>For Landlords</a>
                                </li>
                                <li onClick={() => navigate('/for-tenants')}>
                                    <a>For Tenants</a>
                                </li>
                                <li onClick={() => navigate('/faq')}>
                                    <a>FAQ's</a>
                                </li>
                                <div
                                    style={{
                                        height: 30,
                                        width: 2,
                                        backgroundColor: '#66686d',
                                        marginRight: 10,
                                        marginLeft: 10
                                    }}
                                />

                                {user?.isLogin ? (
                                    <>
                                        <Box onClick={handleClick} sx={{ display: 'flex', alignItems: 'center', ml: 3, cursor: 'pointer' }}>
                                            <Typography sx={{ fontSize: 14, mr: 1 }}>{user?.name?.split(' ')[0]}</Typography>
                                            <Badge badgeContent={0} color="primary">
                                                <Avatar sx={{ width: 35, height: 35 }} alt={user?.name} src="/static/images/avatar/1.webp" />
                                            </Badge>
                                        </Box>
                                        <AccountMenu handleClose={handleClose} open={open} anchorEl={anchorEl} />
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <a onClick={() => navigate('/register')}>Register</a>
                                        </li>
                                        <li>
                                            <a onClick={() => navigate('/login')}>Sign In</a>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </Box>
                    </Toolbar>
                </div>
            </Box>

            <Box component={'nav'}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: '100%'
                        }
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
};

export default memo(Header);
