import React from 'react';
import Navbar from '../../layout/Navbar';

function UnAuthorized() {
    return (
        <Navbar>
            <div>
                <h3>You are not authorized to access this page</h3>
            </div>
        </Navbar>
    );
}

export default UnAuthorized;
