import React from 'react';
import Navbar from '../../layout/Navbar';

function Missing() {
    return (
        <Navbar>
            <div>
                <h3>404 Not Found</h3>
            </div>
        </Navbar>
    );
}

export default Missing;
