import React, { useState } from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/constants';
import useFetch from '../../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../../utils/helpers';
import Countdown from '../../../../components/CountDown';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRequest } from '../../../../utils/api';
import ErrorText from '../../../../components/error/ErrorText';
import CircularLoader from '../../../../components/loader/CircularLoader';
import ModalConfirmation from '../../../../components/modal/ModalConfirmation';

function TBids() {
    const { user } = useSelector((state) => state?.user);

    const { data, isLoading, error, refetch: refetchBid } = useFetch(`bids/user/${user?.userId}`);

    const [open, setOpen] = useState({ type: '', id: null });
    const [loading, setLoading] = useState(false);

    const cancelBid = async (bidId) => {
        setLoading(true);
        try {
            const res = await apiRequest('delete', `bids/cancel/${open?.id}`);

            if (res?.data) {
                refetchBid();
                return toast.success('Your bid has been cancelled');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SideMenu>
            <Box sx={{ padding: '2.5rem', overflowY: 'auto' }}>
                <Typography variant="h5" fontWeight={'bold'} color={COLORS.primary}>
                    My Bids - <span style={{ fontWeight: '400' }}>Current Bids</span>
                </Typography>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                <TableCell>My bid</TableCell>
                                <TableCell>Current bid</TableCell>
                                <TableCell>Bidding status</TableCell>
                                <TableCell>Time remaining</TableCell>
                                <TableCell width={150}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <CircularLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {error && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <ErrorText error={error} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!!data &&
                                data?.map((row) => (
                                    <TableRow key={row?._id} sx={{ borderBottom: '1px solid black' }}>
                                        <TableCell component="th" scope="row">
                                            {row?.property?.title}
                                        </TableCell>
                                        <TableCell>${formatAmount(row?.myBid || 0)}</TableCell>
                                        <TableCell>${formatAmount(row?.property?.currentBid || 0)}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>
                                            <Countdown targetDate={row?.property?.endDateTime} />
                                        </TableCell>
                                        <TableCell align="right">
                                            {row?.status !== 'secured' && (
                                                <Button
                                                    onClick={() => setOpen({ type: 'cancelConfirmation', id: row?._id })}
                                                    variant="contained"
                                                    color="error"
                                                >
                                                    Cancel Bid
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ModalConfirmation
                open={open?.type == 'cancelConfirmation'}
                loading={loading}
                header={'Cancel Bid'}
                description={'Are you sure you want to cancel your bid?'}
                onClickYes={() => cancelBid()}
                onClickNo={() => setOpen({ type: '', id: null })}
            />
        </SideMenu>
    );
}

export default TBids;
