export const COLORS = {
    primary: '#2f3c45',
    secondory: '#37bfcb',
    white: '#fff',
    black: '#000',
    lightWhite: '#dfe4e7',
    lightBlack: '#444',
    gray: 'gray'
}

// https://ppr-server.onrender.com/api/v1/ -- staging
// http://localhost:5000/ -- local
// export const BASE_URL = `https://ppr-server.onrender.com/`;
export const BASE_URL = `https://app.perfectpayrentbid.com/`; // --production
// export const BASE_URL = `http://localhost:5000/`;
export const API_VERSION = 'api/v1/';
export const API_URL = `${BASE_URL}${API_VERSION}`;
export const AVATAR_URL = `${BASE_URL}avatar/`;
export const PROPERTY_URL = `${BASE_URL}property/`;
export const GOOGLE_MAP_KEY = 'AIzaSyC0uoQc-IzToRto5hcDOmalgh5Os98OUbg'