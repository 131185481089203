import moment from 'moment';

export default function isEmpty(value) {
    if (value === null || value === undefined) {
        return true;
    } else if (typeof value === 'string' || Array.isArray(value)) {
        return value.length === 0;
    } else if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    } else {
        return false;
    }
}

export const customEmailValidation = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
};

export const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the cleaned number has exactly 10 digits
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        // Return the formatted phone number
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    // Return the original phone number if it doesn't match the pattern
    return phoneNumber;
};

export const getTimeDifference = (endDateTime) => {
    const end = moment(endDateTime);
    const now = moment();

    // Calculate the difference in milliseconds
    const diff = end.diff(now);

    // If the difference is negative, return "0 hrs 0 min"
    if (diff <= 0) {
        return '0 hrs 0 min';
    }

    // Convert the difference to duration
    const duration = moment.duration(diff);

    // Extract days, hours, and minutes
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    // Format the output
    if (days > 0) {
        return `${days} days`;
    } else {
        return `${hours} hrs ${minutes} min`;
    }
};

export const formatAmount = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberToWords = (number) => {
    const words = [
        '0',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
        'twenty',
        'thirty',
        'forty',
        'fifty',
        'sixty',
        'seventy',
        'eighty',
        'ninety'
    ];

    if (number < 0) return 'minus ' + numberToWords(-number);
    if (number <= 20) return words[number];
    if (number < 100) return words[20 + Math.floor(number / 10)] + (number % 10 ? '-' + words[number % 10] : '');
    if (number < 1000) return words[Math.floor(number / 100)] + ' hundred' + (number % 100 ? ' and ' + numberToWords(number % 100) : '');
    if (number < 1000000)
        return numberToWords(Math.floor(number / 1000)) + ' thousand' + (number % 1000 ? ' ' + numberToWords(number % 1000) : '');
    if (number < 1000000000)
        return numberToWords(Math.floor(number / 1000000)) + ' million' + (number % 1000000 ? ' ' + numberToWords(number % 1000000) : '');
    return number;
};
