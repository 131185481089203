import React from 'react';
import Navbar from '../../layout/Navbar';
import { Box, Button, Grid, Typography } from '@mui/material';
import { COLORS } from '../../utils/constants';
import Icon1 from '../../assets/images/icon-1.webp';
import Icon2 from '../../assets/images/icon-2.webp';
import Icon3 from '../../assets/images/icon-3.webp';
import Icon4 from '../../assets/images/icon-4.webp';
import TenantSocial from '../../assets/images/tenant-social.webp';
import MobileMoc from '../../assets/images/PPR_Mockup.webp';
import QRCode from '../../assets/images/qr.webp';
import AppleBtn from '../../assets/svg/apple-app.svg';
import AndroidBtn from '../../assets/svg/android-app.svg';
import LandingPageFooter from '../../layout/footer/LandingPageFooter';
import BidPlatForm from '../home/BidPlatForm';
import BidPlatForm2 from '../home/BidPlatForm2';

const forTenant = [
    {
        image: Icon1,
        title: 'Cashless Security Deposit',
        subtitle: "Say goodbye to security deposits. We guarantee your lease to your landlord, so you don't need to pay a security deposit."
    },
    {
        image: Icon2,
        title: 'Loss of Income Assistance',
        subtitle: "If you lose your income, we'll pay your rent for up to 3 months, and you don't have to pay us back!"
    },
    {
        image: Icon3,
        title: 'Rent Deferral Assistance',
        subtitle: "Need extra time to pay your rent? We'll cover your rent for up to 3 weeks without late fees."
    },
    {
        image: Icon4,
        title: 'Rent Advance Assistance',
        subtitle: "Facing a cash flow problem? We'll pay your rent for that month, and you can pay us back within 3 months."
    },
    {
        image: Icon4,
        title: 'Tenant Insurance',
        subtitle: 'Enjoy peace of mind with our group Tenant Legal Liability Insurance, covering contents and liability at no cost.'
    },
    {
        image: Icon4,
        title: 'Credit Building Support',
        subtitle: "We'll help you build your credit score by reporting your rent payments to major credit reporting agencies."
    }
];

const signup = [
    {
        title: '1. Register',
        subtitle: 'Complete the PerfectPayRentBid application to qualify for bidding.'
    },
    {
        title: '2. Explore Listings',
        subtitle: 'Browse through our pre-construction and move-in-ready, vacant rental listings to find your perfect home.'
    },
    {
        title: '3. Bidding',
        subtitle: `Set your own price, make a bid, or choose "Secure Now" to lock in your new home.`
    },
    {
        title: '4. Digital Agreement',
        subtitle: 'Execute your rental agreement, set up your rent payment account, and get your move-in date.'
    },
    {
        title: '5. Access Services',
        subtitle: 'Explore our range of services, support, and rent assistance options available when you need them.'
    }
];

function ForTenants() {
    return (
        <Navbar>
            <div>
                <div className="container">
                    {/* <Box sx={{ py: 7, px: 2 }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            Welcome to <span style={{ color: COLORS.secondory }}>PerfectPay</span>RentBid - Empowering Tenants
                        </Typography>

                        <Typography variant="subtitle1" sx={{ textAlign: 'center', mt: 2 }}>
                            Get ready to embark on a renting journey like never before with{' '}
                            <span style={{ color: COLORS.secondory }}>PerfectPay</span>RentBid. Discover how our platform can redefine your
                            renting experience, offering you transparency, choice, and confidence. Explore the tailored features designed
                            just for you below
                        </Typography>
                    </Box> */}

                    <p style={{ fontSize: 24, marginTop: 60, fontWeight: '700', color: '#2F3C45'}}>
                        Welcome to PerfectPayRentBid - Empowering Tenants
                    </p>
                    <p style={{ fontSize: 16, lineHeight: 1.6, fontWeight: '400', color: '#2F3C45', marginTop: 10 }}>
                        Get ready to embark on a renting journey like never before with PerfectPayRentBid. Discover how our platform can
                        redefine your renting experience, offering you transparency, choice, and confidence. Explore the tailored features
                        designed just for you below.
                    </p>

                    <div style={{ padding: 50, marginTop: 50, marginBottom: 100 }}>
                        <Grid container gap={2} sx={{ justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5', py: 2 }}>
                            {forTenant?.map((item, index) => (
                                <Grid item lg={3.8} md={4} sm={6} xs={12} key={index}>
                                    <Box
                                        sx={{
                                            backgroundColor: COLORS.white,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minHeight: 250,
                                            px: 2
                                        }}
                                    >
                                        <img src={item?.image} height={80} width={80} style={{ objectFit: 'contain' }} />

                                        <Typography  my={1} style={{fontSize: 18, textAlign: 'center', fontWeight: '600'}}>
                                            {item?.title}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ textAlign: 'center', fontSize: 13, color: '#2f3c45' }}>
                                            {item?.subtitle}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                    <BidPlatForm />
                    <BidPlatForm2 />

                    {/* <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', mb: 7 }}>
                        {forTenant?.map((item, index) => (
                            <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                <Box
                                    sx={{
                                        backgroundColor: COLORS.white,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        minHeight: 250,
                                        px: 2
                                    }}
                                >
                                    <img src={item?.image} height={80} width={80} style={{ objectFit: 'contain' }} />

                                    <Typography variant="h6" fontWeight={'500'} my={1}>
                                        {item?.title}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ textAlign: 'center', fontSize: 14 }}>
                                        {item?.subtitle}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container justifyContent={'space-around'}>
                        <Grid item lg={5} sm={12} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography variant="h4" sx={{ fontWeight: '600' }}>
                                    Sign up on the
                                    <br />
                                    <span style={{ color: COLORS.secondory }}>PerfectPay</span>Rent Platform
                                </Typography>

                                <Typography my={3} sx={{ fontSize: 18 }}>
                                    Need help with your tenant account setup? Reach out to our customer success team.
                                </Typography>

                                <Button variant={'contained'} sx={{ textTransform: 'capitalize', width: 150, bgcolor: COLORS.secondory }}>
                                    Get Connected
                                </Button>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            lg={5}
                            sm={12}
                            xs={12}
                            sx={{ bgcolor: '#daeff2', p: { lg: 4, sm: 3, xs: 2 }, my: { sm: 3, xs: 3 } }}
                        >
                            {signup?.map((item, index) => (
                                <Grid key={index} item lg={12} md={12} sm={12} xs={12} my={1}>
                                    <Box sx={{ p: 2, bgcolor: COLORS.white }}>
                                        <Typography sx={{ fontSize: 18, fontWeight: '500' }}>{item?.title}</Typography>
                                        <Typography sx={{ fontSize: 14, mt: 0.5 }}>{item?.subtitle}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} justifyContent={'space-between'} alignItems={'center'} sx={{ my: 7 }}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box sx={{ width: 300, height: 300, margin: 'auto', borderRadius: 150, overflow: 'hidden' }}>
                                <img src={TenantSocial} height={'100%'} width={'100%'} />
                            </Box>
                        </Grid>

                        <Grid item lg={8} md={7.5} sm={6} xs={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600', color: '#444' }}>
                                Start your journey towards effortless renting today with PerfectPayRentBid, where your satisfaction and
                                peace of mind are our top priorities.
                            </Typography>

                            <Button
                                variant={'contained'}
                                sx={{ textTransform: 'capitalize', width: 150, mt: 2, bgcolor: COLORS.secondory }}
                            >
                                Tenant FAQs
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container alignItems={'center'} justifyContent={'space-between'} sx={{pb: {xs: 5, lg: 0}}}>
                        <Grid item lg={3} md={3} sx={{display: {xs: 'none', lg: 'block', md: 'block'}}}>
                            <img src={MobileMoc} width={'100%'} />
                        </Grid>

                        <Grid item lg={4} md={4}>
                            <Box>
                                <Typography variant='h4' sx={{fontWeight: 'bold', textAlign: {xs: 'center', lg: 'left', md: 'left'}}}>Download the <span style={{color: COLORS.secondory}}>PerfectPayRent</span> Platform</Typography>

                                <Box sx={{mt: 2}}>
                                    <img src={AppleBtn} style={{width: 150, marginRight: 20}}/>
                                    <img src={AndroidBtn} style={{width: 150}}/>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={2.5} marginBlockEnd={.5} sx={{display: {xs: 'none', lg: 'block', md: 'block'}}}>
                            <Box sx={{width: '100%', border: '2px solid gray', borderRadius: 4, overflow: 'hidden', p: 3, bgcolor: '#fff'}}>
                                <img src={QRCode} width={'100%'} />
                            </Box>
                        </Grid>
                    </Grid> */}
                </div>
                <LandingPageFooter />
            </div>
        </Navbar>
    );
}

export default ForTenants;
