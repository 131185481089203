import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

function CircularLoader({ sx }) {
    return (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', p: 2, ...sx }}>
            <CircularProgress size={35} />
        </Box>
    );
}

export default CircularLoader;
