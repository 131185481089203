import React from 'react';
import { Box, ButtonBase, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SouthEastRoundedIcon from '@mui/icons-material/SouthEastRounded';

// https://via.placeholder.com/300x150

const BackgroundButton = styled(ButtonBase)(({ theme, imageUrl }) => ({
    position: 'relative',
    width: '300px',
    height: '400px',
    backgroundImage: `url(${imageUrl})`, // Replace with your image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '&:hover .overlay': {
        backgroundColor: 'rgba(0, 0, 0, 0.7)' // Darker on hover
    }
}));

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black with 50% opacity
    transition: 'background-color 0.3s ease'
});

const ButtonText = styled(Typography)({
    color: '#fff',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'left',
    width: 230,
    zIndex: 1
});

export default function ImageButton({ label, onClick, imageUrl }) {
    return (
        <BackgroundButton imageUrl={imageUrl} onClick={onClick}>
            <Overlay className="overlay" />

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                <ButtonText>{label}</ButtonText>

                <IconButton sx={{bgcolor: '#fff'}}>
                    <SouthEastRoundedIcon />
                </IconButton>
            </Box>
        </BackgroundButton>
    );
}
