import { toast } from 'react-toastify';
import { apiRequest } from '../utils/api';

const addToFavorite = async (data) => {
    try {
        const res = await apiRequest('post', 'favorites', data);

        if (res?.data) {
            return true;
        }
    } catch (error) {
        toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        return false;
    }
};

const removeFromFavorite = async (data) => {
    try {
        const res = await apiRequest('put', `favorites`, data);

        if (res?.data) {
            return true;
        }
    } catch (error) {
        toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        return false;
    }
};

export { addToFavorite, removeFromFavorite };
