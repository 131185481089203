import { Divider, Grid, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../../../utils/constants';
import moment from 'moment';

function Utilities({ endDateTime, unitAvailableDate, amenities, utilsByTenant }) {
    const predefinedAmenities = [
        'Gym/fitness centre',
        'Air conditioning',
        'Furnished',
        'Laundry',
        'Parking',
        'Dishwasher',
        'Basement',
        'Font desk',
        'Party room',
        'Natural gas',
        'Pets',
        'Pool',
        'Balcony',
        'Fireplace',
        'Storage locker',
        'Lounge',
        'Theatre room',
        'Games room'
    ];

    const unmatchedAmenities = [];

    amenities.forEach((amenity) => {
        if (!predefinedAmenities.includes(amenity)) {
            unmatchedAmenities.push(amenity);
        }
    });

    return (
        <Grid item container spacing={4} lg={4} md={4} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography fontSize={16} fontWeight={'bold'}>
                    Unit Walkthrough
                </Typography>
                <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

                <Box sx={{ width: '100%', height: 200, bgcolor: 'gray', mt: 2 }}></Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography fontSize={16} fontWeight={'bold'}>
                    Bidding close date:
                </Typography>
                <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

                <Typography color={'#373737'}>{moment(endDateTime)?.format('MMMM D, YYYY [at] ha [ET]')}</Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography fontSize={16} fontWeight={'bold'}>
                    Unit available date:
                </Typography>
                <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

                <Typography color={'#373737'}>{moment(unitAvailableDate)?.format('MMMM Do, YYYY')}</Typography>
            </Grid>

            <Grid item lg={12}  md={12} sm={12} xs={12}>
                <Typography fontSize={16} fontWeight={'bold'}>
                    Utilities paid by tenant:
                </Typography>
                <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

                <Box sx={{ display: 'flex', gap: 4 }}>
                    {utilsByTenant?.map((item) => (
                        <Typography color={'#373737'}>{item}</Typography>
                    ))}
                </Box>
            </Grid>

            <Grid item lg={12}>
                <Typography fontSize={16} fontWeight={'bold'}>
                    Unit amenities:
                </Typography>
                <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {predefinedAmenities.map((amenity) => (
                        <Typography key={amenity} sx={{ color: amenities.includes(amenity) ? 'black' : 'lightgray', width: '45%' }}>
                            {amenity}
                        </Typography>
                    ))}

                    {unmatchedAmenities.map((amenity) => (
                        <Typography key={amenity} sx={{ color: 'black', width: '45%' }}>
                            {amenity}
                        </Typography>
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
}

export default Utilities;
