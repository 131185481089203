import React, { useState } from 'react';
import './style.css';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import Logo from '../../assets/images/logo.svg';
import InstaIcon from '../../assets/images/insta-icon.webp';
import LinkedInIcon from '../../assets/images/linkedin-icon.webp';
import FacebookIcon from '../../assets/images/facebook-icon.webp';
import TwitterIcon from '../../assets/images/twitter-icon.webp';
import SimpleTextField from '../../components/form/Textfield/SimpleTextFIeld';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../utils/constants';
import * as Yup from 'yup';

const styles = {
    linkBox: { ml: 2, display: 'flex', flexDirection: 'column', gap: 3 }
};

function LandingPageFooter() {
    const navigate = useNavigate();

    const [isRememberMe, setIsRememberMe] = useState(false);

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().trim().required('Required'),
        email: Yup.string().trim().required('Required')
    });

    return (
        <div className="footer-container">
            <div className="container">
                <Grid container spacing={3} justifyContent={'space-between'}>
                    <Grid item container spacing={3} lg={8.5}>
                        <Grid item lg={3.5}>
                            <img src={Logo} width={'100%'} style={{ objectFit: 'contain' }} />

                            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: 'center', my: 3.5 }}>
                                <img src={TwitterIcon} width={28} height={28} />
                                <img src={FacebookIcon} width={28} height={28} />
                                <img src={LinkedInIcon} width={28} height={28} />
                                <img src={InstaIcon} width={28} height={28} />
                            </Box>

                            <p className="info-email">info@perfectpayrent.com</p>
                        </Grid>

                        <Grid item lg={2} sx={styles.linkBox}>
                            <p className="main-title">Company</p>
                            <p className="sub-title">Home</p>
                            <p className="sub-title">About Us</p>
                            <p className="sub-title">Get Connected</p>
                        </Grid>

                        <Grid item lg={2} sx={styles.linkBox}>
                            <p className="main-title">For Landlords</p>
                            <p className="sub-title">Landlord Service</p>
                            <p className="sub-title">Landlord FAQs</p>
                        </Grid>

                        <Grid item lg={2} sx={styles.linkBox}>
                            <p className="main-title">For Tenants</p>
                            <p className="sub-title">Tenant Service</p>
                            <p className="sub-title">Tenant FAQs</p>
                        </Grid>

                        <Grid item lg={12}>
                            <p className="paragraph">
                                All information and content on this website and social media platforms are provided by PerfectPayRent LLC
                                and are subject to applicable statutes and regulations, furnished "as is," without warranty of any kind,
                                express or implied, including but not limited to implied warranties of merchantability, fitness for a
                                particular purpose, or non-infringement. See the links for terms, policies, and legal and regulatory
                                notices.
                            </p>
                        </Grid>
                    </Grid>

                    <Grid item lg={3}>
                        <p className="main-title">Subscribe to our Email List</p>
                        <Formik
                            initialValues={{
                                name: '',
                                email: ''
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values, { resetForm }) => console.log(values)}
                        >
                            <Form>
                                <Box sx={{ mt: 2 }}>
                                    <Typography fontSize={'13px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Your Name
                                    </Typography>
                                    <SimpleTextField name={'name'} />
                                </Box>

                                <Box sx={{ my: 2 }}>
                                    <Typography fontSize={'13px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Email
                                    </Typography>
                                    <SimpleTextField name={'email'} />
                                </Box>

                                <FormControlLabel
                                    sx={{ my: -2 }}
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={isRememberMe}
                                            onChange={(e) => setIsRememberMe(e?.target?.checked)}
                                        />
                                    }
                                    label={
                                        <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                            Yes, subscribe me to your email list.
                                        </Typography>
                                    }
                                />

                                <Button type="submit" variant="contained" fullWidth sx={{ mt: 3 }}>
                                    Submit
                                </Button>
                            </Form>
                        </Formik>
                    </Grid>
                </Grid>

                <Grid item lg={2} sx={{ display: 'flex', flexWrap: 'wrap', gap: 8, my: 5, justifyContent: 'center' }}>
                    <p className="sub-title">Terms of Service</p>
                    <p className="sub-title">Privacy Policy</p>
                    <p className="sub-title">Do Not Sell</p>
                    <p className="sub-title">Legal and Regulatory Notice</p>
                    <p className="sub-title">ESG - Social Impact Statement</p>
                </Grid>

                <p className="copyright-text">© 2024 PerfectPayRent LLC. All rights reserved. </p>
            </div>
        </div>
    );
}

export default LandingPageFooter;
