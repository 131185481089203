import {
    Box,
    Divider,
    Grid,
    Typography,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    Button,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import React, { useState } from 'react';
import GradientButton from '../../../components/buttons/GradientButton';
import { COLORS } from '../../../utils/constants';
import { formatAmount } from '../../../utils/helpers';
import Countdown from '../../../components/CountDown';
import SimpleTextField from '../../../components/form/Textfield/SimpleTextFIeld';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiRequest } from '../../../utils/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmation from '../../../components/modal/ModalConfirmation';
import ModalCustom from '../../../components/modal/ModalCustom';
import CircularLoader from '../../../components/loader/CircularLoader';

function PropertyBidInfo({
    endDateTime,
    currentBid = 0,
    maximumBid = 0,
    loadBidStatus,
    loadingProperty,
    bidData,
    bidRefetch,
    propertyRefetch,
    propertyId,
    property
}) {
    const location = useLocation();
    const { user } = useSelector((state) => state?.user);
    const navigate = useNavigate();
    const [open, setOpen] = useState('false');
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setOpen('false');
    };

    const handleBid = async (val) => {
        if (!user?.isLogin) {
            navigate('/login', { state: { from: location } });
            return;
        }
        setIsLoading(true);
        try {
            let url = `bids`;
            let method = 'post';

            if (open === 'update') {
                url = `bids/${bidData?._id}`;
                method = 'put';
            }

            const res = await apiRequest(method, url, { user: user?.userId, property: propertyId, myBid: Number(val?.amountBid) });

            if (res?.data) {
                propertyRefetch();
                bidRefetch();
                handleClose();
                return toast.success(open === 'update' ? 'Your bid updated successfully!' : 'Your bid created successfully!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const secureBid = async (val) => {
        if (!user?.isLogin) {
            navigate('/login', { state: { from: location } });
            return;
        }
        setIsLoading(true);
        try {
            const res = await apiRequest('post', 'bids/secure', { user: user?.userId, property: propertyId });

            if (res?.data) {
                propertyRefetch();
                bidRefetch();
                handleClose();
                return toast.success('You have secured the bid!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const cancelBid = async () => {
        setIsLoading(true);
        try {
            const res = await apiRequest('delete', `bids/cancel/${bidData?._id}`);

            if (res?.data) {
                propertyRefetch();
                bidRefetch();
                handleClose();
                return toast.success('Your bid has been cancelled');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Grid item lg={4.5} md={4} sm={12} xs={12} sx={{ backgroundColor: '#dfe4e7', p: 5, position: 'relative' }}>
            {loadingProperty && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        bgcolor: '#00000080',
                        m: -5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {property?.isActive === false && property?.bidClosedDate ? (
                <Typography textAlign={'center'}>This property has been secured and is no longer available</Typography>
            ) : (
                <>
                    <Box>
                        <Typography fontSize={16} fontWeight={'bold'}>
                            Time remaining:
                        </Typography>

                        <Typography fontSize={26} fontWeight={'bold'}>
                            <Countdown targetDate={endDateTime} />
                        </Typography>
                    </Box>

                    <Divider sx={{ bgcolor: COLORS.primary, my: 2 }} />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <Typography fontSize={16} fontWeight={'bold'}>
                                Current Bid:
                            </Typography>
                            <Typography fontSize={26} fontWeight={'bold'}>
                                ${formatAmount(currentBid)}
                            </Typography>
                        </div>

                        <div>
                            {!loadBidStatus && (
                                <>
                                    {bidData ? (
                                        <>
                                            <p style={{ marginBottom: 10, fontSize: 14 }}>Your Bid: ${formatAmount(bidData?.myBid)}</p>
                                            <GradientButton onClick={() => setOpen('cancel')} title={'Cancel Bid'} bgColor="red" />
                                            <GradientButton
                                                onClick={() => setOpen('update')}
                                                title={'Update Bid'}
                                                style={{ marginLeft: 10 }}
                                            />
                                        </>
                                    ) : (
                                        <GradientButton onClick={() => setOpen('add')} title={'Place Bid'} isGradient />
                                    )}
                                </>
                            )}
                        </div>
                    </Box>

                    <Divider sx={{ bgcolor: COLORS.primary, my: 2 }} />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <Typography fontSize={16} fontWeight={'bold'}>
                                Secure now Bid:
                            </Typography>
                            <Typography fontSize={26} fontWeight={'bold'}>
                                ${formatAmount(maximumBid)}
                            </Typography>
                        </div>

                        <div>
                            <GradientButton onClick={() => setOpen('secure')} title={'Secure Now!'} />
                        </div>
                    </Box>

                    <Divider sx={{ bgcolor: COLORS.primary, my: 2, mb: 4 }} />

                    <div>
                        <p>View location and market analysis below</p>
                    </div>

                    <PlaceBid
                        title="Place your bid"
                        currentBid={currentBid}
                        maximumBid={maximumBid}
                        open={open === 'add'}
                        handleSubmit={(val) => handleBid(val)}
                        handleClose={handleClose}
                        loading={isLoading}
                    />

                    <PlaceBid
                        title="Update your bid"
                        currentBid={currentBid}
                        maximumBid={maximumBid}
                        open={open === 'update'}
                        handleSubmit={(val) => handleBid(val)}
                        handleClose={handleClose}
                        type="update"
                        loading={isLoading}
                    />

                    <ModalConfirmation
                        open={open === 'cancel'}
                        loading={isLoading}
                        header={'Cancel Bid'}
                        description={'Are you sure you want to cancel your bid?'}
                        onClickYes={() => cancelBid()}
                        onClickNo={handleClose}
                    />
                    <ModalConfirmation
                        open={open === 'secure'}
                        loading={isLoading}
                        header={'Secure Bid'}
                        description={'Are you sure you want to secure this property?'}
                        onClickYes={() => secureBid()}
                        onClickNo={handleClose}
                    />
                </>
            )}
        </Grid>
    );
}

const PlaceBid = ({ open, currentBid, maximumBid, title, type = 'add', handleSubmit, handleClose, loading }) => {
    return (
        <ModalCustom open={open} title={title}>
            <Formik
                initialValues={{
                    amountBid: 0
                }}
                validationSchema={Yup.object().shape({
                    amountBid: Yup.number()
                        .typeError('Amount must be a number')
                        .min(currentBid + 10, 'Your bid should be atleast $10 up from current bid')
                        .max(maximumBid - 1, 'Your bid should be less than maximum bid or you can just secure now the property')
                        .required('Required')
                })}
                onSubmit={(values) => handleSubmit(values)}
            >
                <Form>
                    <SimpleTextField
                        sx={{ mt: 1 }}
                        type="number"
                        label="Your bidding amount"
                        name="amountBid"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon sx={{ fontSize: 18 }} />
                                </InputAdornment>
                            )
                        }}
                    />

                    <Box sx={{ float: 'right', mt: 2 }}>
                        {loading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <>
                                <Button sx={{ color: 'red' }} onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button type="submit">{type === 'add' ? 'Place Bid' : 'Update Bid'}</Button>
                            </>
                        )}
                    </Box>
                </Form>
            </Formik>
        </ModalCustom>
    );
};

export default PropertyBidInfo;
