import React from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';

function TRental() {
    return (
        <SideMenu>
            <div>Rental</div>
        </SideMenu>
    );
}

export default TRental;
