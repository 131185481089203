// PropertiesList.js
import React, { useEffect, useState } from 'react';
import Navbar from '../../layout/Navbar';
import { Box, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchBar from './comps/SearchBar';
import Filters from './comps/Filters';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';
import useQueryParams from '../../hooks/useQueryParams';
import { API_URL } from '../../utils/constants';
import AppsIcon from '@mui/icons-material/Apps';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GridView from './GridView';
import LocationView from './LocationView';
import { useSelector } from 'react-redux';
import { addToFavorite, removeFromFavorite } from '../../services/favorites';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialParams = {
    searchQuery: '',
    beds: '0,3',
    baths: '0,3',
    priceRange: '0,5000',
    page: 1,
    itemsPerPage: '10',
    sortBy: 'featured',
    view: 'grid'
};

const PropertiesList = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state?.user);
    const [params, setParams] = useQueryParams(initialParams);
    const debouncedParams = useDebounce(params, 500);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            setData([]);
            try {
                const response = await axios.get(`${API_URL}properties`, {
                    params: {
                        pageNumber: debouncedParams?.page,
                        pageSize: debouncedParams?.itemsPerPage,
                        querySearch: debouncedParams?.searchQuery,
                        bed: debouncedParams?.beds,
                        bath: debouncedParams?.baths,
                        priceRange: debouncedParams?.priceRange,
                        sortBy: debouncedParams?.sortBy,
                        // isFeatured: true,
                        user: user?.userId || null
                    }
                });
                setData(response.data);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [debouncedParams]);

    // useEffect(() => {
    //     return () => {
    //         if (window.google) {
    //             delete window.google;
    //         }
    //         const mapsScript = document.querySelector(`script[src*="maps.googleapis.com"]`);
    //         if (mapsScript) {
    //             mapsScript.remove();
    //         }
    //     };
    // }, []);

    const handleFavorite = async (event, propertyId, val) => {
        event?.stopPropagation();
        if (!user?.userId) {
            return toast.error('Please login to add this property to favorite');
        }
        setData((prevItems) => {
            // Find the index of the item to update
            const index = prevItems?.items?.findIndex((item) => item._id === propertyId);
            if (index === -1) return prevItems; // Return previous state if the item is not found

            // Create a shallow copy of the array
            const newItems = [...prevItems?.items];
            // Update the item directly
            newItems[index] = { ...newItems[index], isFavorite: !newItems[index].isFavorite };

            return { ...prevItems, items: newItems };
        });
        if (val === true) {
            await addToFavorite({ user: user?.userId, property: propertyId });
        }
        if (val === false) {
            await removeFromFavorite({ user: user?.userId, property: propertyId });
        }
    };

    return (
        <Navbar>
            <Box sx={{ backgroundColor: '#0c3455', p: 3, display: 'flex' }}>
                <Grid container>
                    <SearchBar searchQuery={params?.searchQuery} setSearchQuery={(value) => setParams({ ...params, searchQuery: value })} />
                    <Filters
                        beds={params?.beds?.split(',')}
                        setBeds={(value) => setParams({ ...params, beds: value?.join(',') })}
                        baths={params?.baths?.split(',')}
                        setBaths={(value) => setParams({ ...params, baths: value?.join(',') })}
                        priceRange={params?.priceRange?.split(',')}
                        setPriceRange={(value) => setParams({ ...params, priceRange: value?.join(',') })}
                    />
                </Grid>
                <Box display={'flex'}>
                    <IconButton onClick={() => setParams({ ...params, view: 'grid' })}>
                        <AppsIcon sx={{ color: params?.view === 'grid' ? '#34aeeb' : '#fff', fontSize: 30 }} />
                    </IconButton>

                    <IconButton onClick={() => setParams({ ...params, view: 'location' })}>
                        <LocationOnIcon sx={{ color: params?.view === 'location' ? '#34aeeb' : '#fff', fontSize: 30 }} />
                    </IconButton>
                </Box>
            </Box>

            <Box>
                {params?.view === 'grid' ? (
                    <GridView
                        data={data}
                        isLoading={isLoading}
                        error={error}
                        sortBy={params?.sortBy}
                        setSortBy={(value) => setParams({ ...params, sortBy: value })}
                        itemPerPage={params?.itemsPerPage}
                        setItemPerPage={(value) => setParams({ ...params, itemsPerPage: value })}
                        page={params?.page}
                        setPage={(value) => setParams({ ...params, page: value })}
                        onClickFavorite={handleFavorite}
                    />
                ) : (
                    <LocationView
                        data={data}
                        isLoading={isLoading}
                        error={error}
                        sortBy={params?.sortBy}
                        setSortBy={(value) => setParams({ ...params, sortBy: value })}
                        itemPerPage={params?.itemsPerPage}
                        setItemPerPage={(value) => setParams({ ...params, itemsPerPage: value })}
                        page={params?.page}
                        setPage={(value) => setParams({ ...params, page: value })}
                        onClickFavorite={handleFavorite}
                    />
                )}
            </Box>
        </Navbar>
    );
};

export default PropertiesList;
