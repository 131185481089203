import React, { useEffect, useRef } from 'react';
import RNTImage from '../../assets/images/renting-and-choice.webp';
import './style.css';

export default function RentAndChoice() {
    return (
        <div className="rnt-gradient">
            <div className="rnt-container container">
                {/* <img src={RNTImage} className="rnt-image" />

                <div className="inner-cont2-c2">
                    <p>Renting and Choice should be as Empowering and Rewarding as Home Ownership</p>
                </div> */}
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <p style={{ fontSize: 20, fontWeight: '700', color: '#2F3C45' }}>Your Rental, Your Choice:</p>
                    <p style={{ fontSize: 16, lineHeight: 1.6, marginTop: 10, fontWeight: '400', color: '#2F3C45' }}>
                        Bid confidently on your ideal living space with PerfectPayRentBid's innovative platform.
                    </p>

                    <div style={{ height: 65 }} />

                    <p style={{ fontSize: 20, fontWeight: '700', color: '#2F3C45' }}>Secure Your Future:</p>
                    <p style={{ fontSize: 16, lineHeight: 1.6, marginTop: 10, fontWeight: '400', color: '#2F3C45' }}>
                        Bid on Pre-construction Properties and secure your dream home up to 18 months before occupancy.
                    </p>

                    <div style={{ height: 65 }} />

                    <p style={{ fontSize: 20, fontWeight: '700', color: '#2F3C45' }}>Instant Approval:</p>
                    <p style={{ fontSize: 16, lineHeight: 1.6, marginTop: 10, fontWeight: '400', color: '#2F3C45' }}>
                        Bid on Vacant Rentals for immediate possession and move into your desired neighborhood hassle-free.
                    </p>

                    <div style={{ height: 65 }} />

                    <p style={{ fontSize: 20, fontWeight: '700', color: '#2F3C45' }}>Transparent and Secure:</p>
                    <p style={{ fontSize: 16, lineHeight: 1.6, marginTop: 10, fontWeight: '400', color: '#2F3C45' }}>
                        Benefit from market insights, capped rents and much more. Welcome to the future of renting with PerfectPayRentBid.
                    </p>

                    <div style={{ height: 10 }} />

                    <p style={{ fontSize: 16, fontWeight: '400', lineHeight: 1.6, color: '#2F3C45' }}>
                        Benefit from market insights, capped rent, and guaranteed income for landlords. Welcome to the future of renting
                        with PerfectPayRentBid.
                    </p>
                </div>
            </div>
        </div>
    );
}
