import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import Navbar from '../../layout/Navbar';
import 'react-toastify/dist/ReactToastify.css';

import Carousel from '../../components/image/carousel/Carousel';
import { COLORS, GOOGLE_MAP_KEY } from '../../utils/constants';
import PropertyHeader from './comps/PropertyHeader';
import PropertyBidInfo from './comps/PropertyBidInfo';
import ScoreBox from './comps/ScoreBox';
import Utilities from './comps/Utilities';
import MarketAnalysis from './comps/MarketAnalysis';
import CircularLoader from '../../components/loader/CircularLoader';
import ErrorText from '../../components/error/ErrorText';
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader } from '@react-google-maps/api';

const center = {
    lat: -3.745,
    lng: -38.523
};

function PropertyDetail() {
    const { user } = useSelector((state) => state?.user);
    const { id } = useParams();

    const { isLoading, data: property, error, refetch: propertyRefetch } = useFetch(`properties/${id}`);
    const {
        isLoading: loadBidStatus,
        data: bidData,
        error: bidError,
        refetch: bidRefetch
    } = useFetch(`bids/check-user?user=${user?.userId}&property=${id}`);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_KEY
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        <Navbar>
            {isLoading && <CircularLoader sx={{ height: '50vh' }} />}
            {error && <ErrorText error={error} sx={{ height: '50vh' }} />}
            {property && (
                <>
                    <div className="container">
                        <Grid container p={3} px={10}>
                            <PropertyHeader
                                title={property?.title}
                                bedrooms={property?.bedrooms}
                                bathrooms={property?.bathrooms}
                                area={property?.area}
                            />

                            <Grid item container lg={12} xs={12} mt={2} height={400}>
                                <Grid item lg={7.5} md={8} sm={12} xs={12}>
                                    <Carousel images={property?.images} />
                                </Grid>

                                <PropertyBidInfo
                                    startDateTime={property?.startDateTime}
                                    endDateTime={property?.endDateTime}
                                    currentBid={property?.currentBid}
                                    maximumBid={property?.maximumBid}
                                    loadBidStatus={loadBidStatus}
                                    loadingProperty={isLoading}
                                    bidData={bidData}
                                    bidRefetch={bidRefetch}
                                    propertyRefetch={propertyRefetch}
                                    propertyId={id}
                                    property={property}
                                />
                            </Grid>

                            <Grid item container lg={12} spacing={5} xs={12} mt={0}>
                                <Grid item lg={7.4} md={7} sm={12} xs={12}>
                                    <Typography fontSize={16}>
                                        <b>Location</b> analysis
                                    </Typography>
                                    <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

                                    <pre
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                            lineHeight: 1.3
                                        }}
                                    >
                                        {property?.locationAnalysis}
                                    </pre>

                                    <ScoreBox />

                                    <MarketAnalysis
                                        startingBid={property?.startingBid}
                                        maximumBid={property?.maximumBid}
                                        currentBid={property?.currentBid}
                                    />

                                    <Typography fontSize={16}>
                                        <b>Description</b>
                                    </Typography>
                                    <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

                                    <pre
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                            lineHeight: 1.3
                                        }}
                                    >
                                        {property?.description}
                                    </pre>
                                </Grid>

                                <Grid item lg={0.5}>
                                    <Divider orientation="vertical" sx={{ bgcolor: COLORS.primary, width: '0.5px' }} />
                                </Grid>

                                <Utilities
                                    endDateTime={property?.endDateTime}
                                    unitAvailableDate={property?.unitAvailableDate}
                                    amenities={property?.amenities}
                                    utilsByTenant={property?.utilsByTenant}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    {isLoaded && (
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: 400, marginTop: 30 }}
                            center={{ lat: property?.lat, lng: property?.lng } || center}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            zoom={15}
                        >
                            {property?.lat && <MarkerF position={{ lat: property?.lat, lng: property?.lng }} />}
                        </GoogleMap>
                    )}
                </>
            )}
        </Navbar>
    );
}

export default PropertyDetail;
