import React from 'react';
import BuildingImage from '../../assets/images/ppr-bid platform.webp';
import './style.css';
import useFetch from '../../hooks/useFetch';
import { formatAmount } from '../../utils/helpers';
import { PROPERTY_URL } from '../../utils/constants';

export default function BidPlatForm() {
    const { data: property, isLoading, error, refetch } = useFetch(`properties/showcase`);

    return (
        <div className="bid-platform-container">
            {property && (
                <div className="set-box container" style={{ paddingBottom: 30 }}>
                    <p className="title" style={{fontSize: 24, fontWeight: '700'}}>
                        PerfectPayRent<span style={{ color: '#000' }}> Bid Platform</span>
                    </p>

                    <div className="landlord-inner">
                        <div className="bid-platorm-text">
                            <p style={{ fontSize: 16, fontWeight: 'bold' }}>
                                Property Description: <span style={{ color: '#2e3b45', fontWeight: 500 }}>{property?.title}</span>
                            </p>

                            <div style={styles.divider} />

                            <p
                                style={{
                                    textAlign: 'justify',
                                    fontSize: 15,
                                    color: '#646868',
                                    lineHeight: 1.2
                                }}
                            >
                                {property?.description}
                            </p>

                            <p
                                style={{
                                    textAlign: 'justify',
                                    fontSize: 15,
                                    color: '#646868',
                                    lineHeight: 1.2,
                                    marginTop: 15
                                }}
                            >
                                {property?.locationAnalysis}
                            </p>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 15
                                }}
                            >
                                <div style={styles.scoreCard}>
                                    <p style={{ fontSize: 15, color: '#2e3b45' }}>Walk Score</p>
                                    <p style={{ fontSize: 15, color: '#2e3b45' }}>96/100</p>
                                </div>

                                <div style={styles.scoreCard}>
                                    <p style={{ fontSize: 15, color: '#2e3b45' }}>Transit Score</p>
                                    <p style={{ fontSize: 15, color: '#2e3b45' }}>96/100</p>
                                </div>

                                <div style={styles.scoreCard}>
                                    <p style={{ fontSize: 15, color: '#2e3b45' }}>Bike Score</p>
                                    <p style={{ fontSize: 15, color: '#2e3b45' }}>96/100</p>
                                </div>
                            </div>

                            <div style={styles.divider} />

                            <p
                                style={{
                                    fontSize: 15,
                                    color: '#19abb5',
                                    fontWeight: 600,
                                    textAlign: 'center'
                                }}
                            >
                                Starting Bid: ${formatAmount(property?.startingBid)} | Suggested Market Rate Rent $
                                {formatAmount((Number(property?.startingBid) + Number(property?.maximumBid)) / 2)} | Secure Now (Max) Bid $
                                {formatAmount(property?.maximumBid)}
                            </p>

                            <div style={styles.bidBox}>
                                <div
                                    style={{
                                        flex: 0.45,
                                        backgroundColor: '#18abb5',
                                        borderRadius: 10,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: '#fff'
                                        }}
                                    >
                                        Current Bid: ${formatAmount(property?.currentBid)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="bid-platform-image-side">
                            <div style={{ border: '1px solid black', width: 350, right: 0 }}>
                                <img src={`${PROPERTY_URL}${property?.images[0]}`} style={{ width: '100%' }} />

                                <div style={{ padding: 15 }}>
                                    <p style={{ fontSize: 13, fontWeight: 'bold', color: '#000' }}>9.8*</p>
                                    <p style={styles.text1}>{property?.title}</p>

                                    <p style={{ ...styles.text1, fontWeight: 500 }}>
                                        {property?.title}, {property?.city}, {property?.state}, {property?.country}
                                    </p>
                                    <p style={{ ...styles.text1, fontWeight: 500 }}>
                                        {property?.bedrooms} Bedroom, {property?.bathrooms} Bathroom
                                    </p>

                                    {/* <p style={styles.text1}>
                                    Features:{' '}
                                    <span style={{ fontWeight: 500 }}>
                                        stainless steel appliances, walk-incloset, washer dryer, HVAC, smart locks, parking available
                                    </span>
                                </p> */}
                                    <p style={styles.text1}>
                                        Amenities: <span style={{ fontWeight: 500 }}>{property?.amenities?.join(', ')}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    scoreCard: {
        backgroundColor: '#f1f1f1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 170,
        padding: 4
    },
    divider: {
        width: '97%',
        height: 0.5,
        backgroundColor: '#000',
        marginTop: 15,
        marginBottom: 15
    },
    bidBox: {
        width: '97%',
        display: 'flex',
        height: 50,
        backgroundColor: '#f1f1f1',
        marginTop: 10,
        borderRadius: 10,
        overflow: 'hidden',
        position: 'relative'
    },
    text1: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#000',
        letterSpacing: 1.2,
        marginTop: 15
    }
};
