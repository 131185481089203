import { Box, Divider, FormControl, InputAdornment, MenuItem, Pagination, Select, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CircularLoader from '../../components/loader/CircularLoader';
import ErrorText from '../../components/error/ErrorText';
import PropertyCard1 from './comps/PropertyCard1';
import isEmpty from '../../utils/helpers';

function GridView({ data, error, isLoading, sortBy, setSortBy, itemPerPage, setItemPerPage, page, setPage, onClickFavorite }) {
    const navigate = useNavigate();

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, gap: 5 }}>
                <FormControl sx={{ width: 302 }} size="small">
                    <Select
                        sx={{ fontSize: 14 }}
                        startAdornment={
                            <InputAdornment position="start">
                                <Typography fontSize={14}>Sort by:</Typography>
                            </InputAdornment>
                        }
                        value={sortBy}
                        onChange={(e) => setSortBy(e?.target?.value)}
                    >
                        <MenuItem sx={{ fontSize: 14 }} value={'featured'}>
                            Featured
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 14 }} value={'low-to-high'}>
                            Price - Low to High
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 14 }} value={'high-to-low'}>
                            Price - High to Low
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ width: 302 }} size="small">
                    <Select
                        sx={{ fontSize: 14 }}
                        startAdornment={
                            <InputAdornment position="start">
                                <Typography fontSize={14}>Show:</Typography>
                            </InputAdornment>
                        }
                        value={itemPerPage}
                        onChange={(e) => setItemPerPage(e?.target?.value)}
                    >
                        <MenuItem sx={{ fontSize: 14 }} value={'10'}>
                            10 results per page
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 14 }} value={'15'}>
                            15 results per page
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 14 }} value={'20'}>
                            20 results per page
                        </MenuItem>
                    </Select>
                </FormControl>

                <Box sx={{ width: 302 }}>
                    <Typography fontSize={14} color={'gray'}>
                        {data?.paginationMetadata?.totalCount || '0'} Results found
                    </Typography>
                </Box>
            </Box>
            <Divider />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isLoading && <CircularLoader sx={{ height: '50vh' }} />}
                {!!error && <ErrorText error={error} sx={{ height: '50vh' }} />}
                {!isEmpty(data?.items) && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', p: 5, gap: 5 }}>
                        {data?.items?.map((item) => (
                            <PropertyCard1
                                key={item._id}
                                title={item?.title}
                                bedrooms={item?.bedrooms}
                                bathrooms={item?.bathrooms}
                                area={item?.area}
                                image={item?.images[0]}
                                isFavorite={item?.isFavorite}
                                currentBid={item?.currentBid}
                                endDateTime={item?.endDateTime}
                                onClick={() => navigate(`/properties/${item?._id}`)}
                                onClickFavorite={(event) => onClickFavorite(event, item?._id, !item?.isFavorite)}
                            />
                        ))}
                    </Box>
                )}
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                <Pagination
                    count={data?.paginationMetadata?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={(e, value) => setPage(value)}
                />
            </Box>
        </>
    );
}

export default GridView;
