import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, IconButton, Typography } from '@mui/material';
import ImagesIcon from '../../../assets/images/images-icon.png';
import CancelIcon from '@mui/icons-material/Cancel';

const ImageUpload = ({ images, setImages }) => {
    const onDrop = useCallback((acceptedFiles) => {
        const newImages = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
        setImages((prevImages) => [...prevImages, ...newImages].slice(0, 10));
    }, []);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
        noClick: true // Disable click trigger to prevent file browser from opening on any click
    });

    const handleRemoveImage = (file) => {
        setImages(images.filter((i) => i.preview !== file.preview));
    };

    return (
        <Box
            sx={{
                border: '2px dashed black',
                width: '100%',
                height: 485,
                borderRadius: 2,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                bgcolor: '#f0f0f0'
            }}
        >
            <Box {...getRootProps()} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <input {...getInputProps()} />

                {images.length > 0 ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {images.map((file, index) => (
                            <Box
                                key={index}
                                sx={{
                                    position: 'relative',
                                    width: 100,
                                    height: 100,
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundImage: `url(${file.preview})`
                                }}
                            >
                                <IconButton
                                    onClick={() => handleRemoveImage(file)}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0
                                    }}
                                >
                                    <CancelIcon sx={{ color: 'red' }} />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                        <img src={ImagesIcon} alt="icon-images" style={{ width: 70, height: 70 }} />
                        {isDragActive ? (
                            <Typography sx={{ color: '#000', mt: 2 }}>Drop the files here...</Typography>
                        ) : (
                            <Button onClick={open} sx={{ color: '#000' }}>
                                Drag and Drop or Browse Photos
                            </Button>
                        )}
                        <Typography sx={{ color: 'gray', fontSize: 12, mt: 1 }}>
                            Add a minimum of 3 photos and a maximum of 10 photos
                        </Typography>
                    </Box>
                )}
            </Box>

            {images.length > 0 && images.length < 10 && (
                <Button onClick={open} sx={{ mt: 2, color: '#000', backgroundColor: 'lightgray', borderRadius: 2, p: 1 }}>
                    Add More Photos
                </Button>
            )}
        </Box>
    );
};

export default ImageUpload;
