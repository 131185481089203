import React, { useState } from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/constants';
import CustomTabPanel from '../../../../components/tab/CustomTabPanel';
import { CustomTab, CustomTabs } from '../../../../components/tab/CustomTabs';
import Profile from './Profile';
import Security from './Security';
import PreApproval from './PreApproval';
import NotifSettings from './NotifSettings';
import { useSelector } from 'react-redux';
import { apiRequest } from '../../../../utils/api';

const styles = {
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh'
    }
};

function TAccount() {
    let { user } = useSelector((state) => state?.user);
    const [activeTabVal, setActiveTabVal] = React.useState(0);

    const [load, setLoad] = useState({ tab: 0, isLoad: false, error: null });

    const [preTenant, setPreTenant] = useState(null);
    const [userPref, setUserPref] = useState(null);

    const getPreTenant = async () => {
        setLoad({ tab: 2, isLoad: true, error: null });
        try {
            const res = await apiRequest('get', `tenants/${user?.userId}`);
            setPreTenant({ ...res?.data, isCurrentlyLandlord: res?.data?.isCurrentlyLandlord ? 'Yes' : 'No' });
        } catch (error) {
            setLoad({ tab: 2, isLoad: false, error: error });
        } finally {
            setLoad((pre) => ({ ...pre, isLoad: false }));
        }
    };

    const getNotifSettings = async () => {
        setLoad({ tab: 3, isLoad: true, error: null });
        try {
            const res = await apiRequest('get', `user-preferences/${user?.userId}`);
            if (res?.data) {
                setUserPref(res?.data);
            }
        } catch (error) {
            setLoad({ tab: 3, isLoad: false, error: error });
        } finally {
            setLoad((pre) => ({ ...pre, isLoad: false }));
        }
    };

    const handleTabChange = (event, newValue) => {
        if (newValue === 2 && preTenant === null) {
            getPreTenant();
        }
        if (newValue === 3 && userPref === null) {
            getNotifSettings();
        }
        setActiveTabVal(newValue);
    };

    return (
        <SideMenu>
            <Grid container sx={{ padding: '2.5rem', overflowY: 'auto' }}>
                <Grid item lg={12} xs={12}>
                    <Typography variant="h5" fontWeight={'bold'} color={COLORS.primary}>
                        My Account
                    </Typography>
                </Grid>

                <Grid item lg={12} sx={{ mt: 1 }}>
                    <Box sx={{ borderBottom: 1, borderColor: COLORS.black, p: 0, m: 0 }}>
                        {user?.role[0] == '2101' ? (
                            <CustomTabs value={activeTabVal} onChange={handleTabChange} aria-label="custom tabs example">
                                <CustomTab label="Profile" />
                                <CustomTab label="Security" />
                            </CustomTabs>
                        ) : (
                            <CustomTabs value={activeTabVal} onChange={handleTabChange} aria-label="custom tabs example">
                                <CustomTab label="Profile" />
                                <CustomTab label="Security" />
                                <CustomTab label="Pre-approval" />
                                <CustomTab label="Notification Settings" />
                            </CustomTabs>
                        )}
                    </Box>

                    <CustomTabPanel value={activeTabVal} index={0}>
                        {user?.userName ? (
                            <Profile />
                        ) : (
                            <Grid item sx={styles.centerContent} lg={12} xs={12}>
                                <CircularProgress size={35} color="inherit" />
                            </Grid>
                        )}
                    </CustomTabPanel>

                    <CustomTabPanel value={activeTabVal} index={1}>
                        <Security />
                    </CustomTabPanel>

                    <CustomTabPanel value={activeTabVal} index={2}>
                        {load?.isLoad && activeTabVal === load?.tab ? (
                            <Grid item sx={styles.centerContent} lg={12} xs={12}>
                                <CircularProgress size={35} color="inherit" />
                            </Grid>
                        ) : !!load?.error ? (
                            <Grid item sx={styles.centerContent} lg={12} xs={12}>
                                <Typography>{load?.error?.response?.data?.message || load?.error?.message}</Typography>
                            </Grid>
                        ) : (
                            <PreApproval tenant={preTenant} getTenant={getPreTenant} />
                        )}
                    </CustomTabPanel>

                    <CustomTabPanel value={activeTabVal} index={3}>
                        {load?.isLoad && activeTabVal === load?.tab ? (
                            <Grid item sx={styles.centerContent} lg={12} xs={12}>
                                <CircularProgress size={35} color="inherit" />
                            </Grid>
                        ) : !!load?.error ? (
                            <Grid item sx={styles.centerContent} lg={12} xs={12}>
                                <Typography>{load?.error?.response?.data?.message || load?.error?.message}</Typography>
                            </Grid>
                        ) : (
                            <NotifSettings userPref={userPref} getUserPref={getNotifSettings} />
                        )}
                    </CustomTabPanel>
                </Grid>
            </Grid>
        </SideMenu>
    );
}

export default TAccount;
