import React from 'react';
import './style.css';
import { Button } from '@mui/material';
import { COLORS } from '../../utils/constants';

function PreBuild() {
    return (
        <div>
            <div className="container">
                <p className="title" style={{ color: '#2F3C45', fontSize: 24 }}>
                    Join Us in Redefining Renting
                </p>
                <p className="ts-subtitle" style={{ marginBottom: 50, fontSize: 16, lineHeight: 1.6, fontWeight: '400', color: '#2F3C45' }}>
                    Whether you're a Tenant searching for the perfect home or a landlord seeking security and peace of mind,
                    PerfectPayRentBid is here to help. Welcome to the future of renting. Welcome to PerfectPayRentBid.
                </p>
            </div>
            <div className="pre-build-container">
                <p className="title" style={{ fontSize: 27, fontWeight: '700', marginTop: -30 }}>
                    Sign Up
                </p>
                <p className="pre-build-subtitle" style={{ fontWeight: '700' }}>
                    Get pre-approved to bid on your new rental
                </p>
                <Button variant={'contained'} sx={{ textTransform: 'capitalize', color: '#fff', boxShadow: 0, borderRadius: 0,  width: 150, mt: 4, bgcolor: COLORS.secondory }}>
                    Get Started
                </Button>
            </div>
        </div>
    );
}

export default PreBuild;
