import React from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';

const MaskedInput = ({ field, form, mask, label, ...props }) => {
    const { name } = field;
    const { touched, errors, setFieldValue } = form;
    const errorText = touched[name] && errors[name];

    return (
        <InputMask mask={mask} value={field.value} onChange={(e) => setFieldValue(name, e.target.value)} {...props}>
            {(inputProps) => (
                <TextField
                    size="small"
                    {...inputProps}
                    label={label}
                    variant="outlined"
                    fullWidth
                    error={!!errorText}
                    helperText={errorText}
                    inputProps={{ style: { fontSize: '15px' } }}
                />
            )}
        </InputMask>
    );
};

export default MaskedInput;
