import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../../../utils/constants';
import GradientButton from '../../../components/form/button/GradientButton';
import SimpleTextField from '../../../components/form/Textfield/SimpleTextFIeld';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { EnterOTP } from './EnterOTP';
import { EnterNewPassword } from './EnterNewPassword';
import { apiRequest } from '../../../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EnterEmail() {
    const [showComp, setShowComp] = useState('email');
    const [email, setEmail] = useState(null);
    const [token, setToken] = useState(null);

    return (
        <Grid container sx={{ padding: '3rem' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h4" fontWeight={'bold'} color={COLORS.primary}>
                    Forgot your password?
                </Typography>
                <Typography variant="h6" color={COLORS.primary} sx={{ mt: 1 }}>
                    Enter your email address below
                </Typography>

                <Divider sx={{ bgcolor: COLORS.primary }} />

                {showComp === 'email' ? (
                    <EmailForm setShowComp={setShowComp} setEmail={setEmail} />
                ) : showComp === 'otp' ? (
                    <EnterOTP email={email} setShowComp={setShowComp} setToken={setToken} />
                ) : showComp === 'newPassword' ? (
                    <EnterNewPassword token={token} />
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
}

const EmailForm = ({ setShowComp, setEmail }) => {
    const [isLoading, setIsLoading] = useState(false);

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required')
    });

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const res = await apiRequest('post', 'users/forgot-password', values);
            if (res?.data) {
                setShowComp('otp');
                setEmail(values?.email);
                return toast.success('An OTP has been sended to your email!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            initialValues={{
                email: ''
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => handleSubmit(values)}
        >
            <Form>
                <Grid item lg={4} sx={{ mt: 4, mb: 2 }}>
                    <Box>
                        <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                            Enter your email
                        </Typography>
                        <SimpleTextField name="email" />
                    </Box>
                </Grid>

                <Grid item lg={12}>
                    <Divider sx={{ bgcolor: COLORS.primary, my: 1, mb: 2 }} />

                    <GradientButton loading={isLoading} type="submit" label="Submit" />
                </Grid>
            </Form>
        </Formik>
    );
};
