import React from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/constants';
import useFetch from '../../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../../utils/helpers';
import Countdown from '../../../../components/CountDown';
import ErrorText from '../../../../components/error/ErrorText';
import CircularLoader from '../../../../components/loader/CircularLoader';
import moment from 'moment';

function Notifications() {
    const { user } = useSelector((state) => state?.user);

    const { data, isLoading, error, refetch: refetchBid } = useFetch(`notifications/${user?.userId}`);

    return (
        <SideMenu>
            <Box sx={{ padding: '2.5rem', overflowY: 'auto' }}>
                <Typography variant="h5" fontWeight={'bold'} color={COLORS.primary}>
                    Notification Center
                </Typography>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={180}>Time</TableCell>
                                <TableCell>Notification</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <CircularLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {error && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <ErrorText error={error} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!!data &&
                                data?.map((row) => (
                                    <TableRow key={row?._id} sx={{ borderBottom: '1px solid black' }}>
                                        <TableCell component="th" scope="row">
                                            {moment(row?.createdOn).fromNow()?.replace('in ', '')}
                                        </TableCell>
                                        <TableCell>{row?.message}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </SideMenu>
    );
}

export default Notifications;
