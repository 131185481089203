import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from './constants';

const instance = axios.create({
    baseURL: `${API_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add an interceptor to include the authorization token in the headers
instance.interceptors.request.use(
    async (config) => {
        const token = localStorage?.getItem('token');

        if (token) {
            const currentTime = Date.now() / 1000;
            const decodedToken = jwtDecode(token);

            if (decodedToken?.exp < currentTime) {
                localStorage.clear();
                window.location.replace('/login');
            } else {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Custom function to wrap around Axios methods
const request = (method, url, data) => {
    return instance[method](url, data);
};

// Export the wrapped function
export const apiRequest = request;

// const refreshToken = async (token) => {
//     try {
//         const decodedToken = jwtDecode(token);
//         const res = await axios({
//             url: `${API_URL}users/refreshToken`,
//             method: 'post',
//             data: {
//                 token,
//                 decode: decodedToken
//             }
//         });

//         if (res?.data) {
//             localStorage.setItem('token', JSON.stringify(res?.data?.token));
//             return res?.data?.token;
//         }
//     } catch (error) {
//         if (error?.response?.status == 403) {
//             localStorage.clear();
//             window.location.replace('/login');
//         } else {
//             return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
//         }
//     }
// };
