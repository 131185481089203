import { Button } from '@mui/material';
import React from 'react';
import MainSection from './MainSection';
import RentAndChoice from './RentAndChoice';
import LandLords from './LandLords';
import Tenants from './Tenants';
import TenantService from './TenantService';
import VacantRentalBid from './VacantRentalBid';
import PreConstruction from './PreConstruction';
import YourChoice from './YourChoice';
import BidPlatForm from './BidPlatForm';
import BidPlatForm2 from './BidPlatForm2';
import Navbar from '../../layout/Navbar';
import LandingPageFooter from '../../layout/footer/LandingPageFooter';
import PreBuild from './PreBuild';
import MakeABid from './MakeABid';

export default function Home() {
    return (
        <Navbar>
            <MainSection />
            <RentAndChoice />
            {/* <LandLords /> */}
            {/* <Tenants /> */}
            {/* <TenantService /> */}
            {/* <VacantRentalBid /> */}
            {/* <PreConstruction /> */}
            {/* <YourChoice /> */}
            {/* <BidPlatForm /> */}
            {/* <MakeABid/> */}
            <PreBuild /> 
            {/* <BidPlatForm2 /> */}
            <LandingPageFooter />
        </Navbar>
    );
}
