import React from 'react';
import Navbar from '../../layout/Navbar';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    styled
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { COLORS } from '../../utils/constants';
import './style.css';
import LandingPageFooter from '../../layout/footer/LandingPageFooter';

const faq = [
    {
        question: 'How does PerfectPayRentBid work for Tenants?',
        answer: 'PerfectPayRentBid empowers Tenants by providing an innovative bidding platform. Tenants can bid on pre-construction properties or secure vacant rentals. The platform also offers market insights and transparent pricing to help Tenants make informed decisions.'
    },
    {
        question: 'What are the benefits for landlords using PerfectPayRentBid?',
        answer: 'Landlords enjoy guaranteed rent for each lease agreement term, co-guaranteed agreements, and zero charges for rent collection. This minimizes the risk of eviction and legal expenses.'
    },
    {
        question: 'What types of properties can I bid on through PerfectPayRentBid?',
        answer: 'PerfectPayRentBid allows bidding on a variety of properties, including pre-construction properties and vacant rentals.'
    },
    {
        question: 'How does the bidding process work?',
        answer: 'Tenants can bid on properties of interest through our platform. They can set their bid price or choose to secure the property immediately. Landlords review the bids and select the most suitable tenant.'
    },
    {
        question: 'What happens if my bid is accepted?',
        answer: 'If your bid is accepted, you will receive instant approval to move into the property once the bid is secured.'
    },
    {
        question: 'Can I get assistance if I lose my income or need rent deferral?',
        answer: 'Yes, PerfectPayRentBid offers assistance, including loss of income assistance and rent deferral options, to help Tenants facing financial challenges.'
    },
    {
        question: 'How does PerfectPayRentBid ensure transparency in pricing?',
        answer: 'PerfectPayRentBid provides market insights and transparent pricing, allowing Tenants to know exactly what they are bidding on and ensuring fair and informed decisions.'
    },
    {
        question: 'How can I list my property on PerfectPayRentBid as a landlord?',
        answer: 'Landlords can sign up and create a detailed listing for their property on the PerfectPayRentBid platform to start receiving bids from potential tenants.'
    },
    {
        question: 'Is there a fee to register as a Tenant or landlord on PerfectPayRentBid?',
        answer: 'No, there is no fee to register as a Tenant or landlord on our platform.'
    },
    {
        question: 'How long does it take for a bid to be approved?',
        answer: "The approval process varies depending on the landlord's review time. Typically, bids are approved within a few days."
    },
    {
        question: 'Can I customize my bid based on specific preferences or requirements?',
        answer: 'Yes, Tenants have the flexibility to customize their bids based on preferences such as rental price, location, amenities, and move-in date.'
    },
    {
        question: 'What happens if I change my mind after placing a bid?',
        answer: 'Tenants can retract their bid before it is accepted by the landlord. Once a bid is accepted, it is binding, and Tenants are expected to honor the terms of the agreement.'
    },
    {
        question: 'Are there any restrictions on who can bid on properties through PerfectPayRentBid?',
        answer: 'Tenants must meet certain eligibility criteria, such as providing proof of income and passing background checks, to bid on properties.'
    },
    {
        question: 'What measures does PerfectPayRentBid take to ensure the safety and security of Tenants and landlords?',
        answer: 'PerfectPayRentBid conducts thorough background checks on both Tenants and landlords to verify their identities and ensure a safe and secure renting experience for all parties involved.'
    },
    {
        question: 'Can I view the property in person before placing a bid?',
        answer: 'Yes, Tenants have the option to schedule viewings of properties before placing a bid to ensure that it meets their requirements and expectations.'
    },
    {
        question: 'What happens if there are disputes between Tenants and landlords?',
        answer: 'PerfectPayRentBid provides dispute resolution services to help resolve any conflicts that may arise between Tenants and landlords.'
    },
    {
        question: 'How can I contact customer support if I have questions or need assistance?',
        answer: "Tenants and landlords can contact PerfectPayRentBid's customer support team via phone, email, or live chat for assistance with any inquiries or issues."
    }
];

function FAQ() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
        '&:hover': {
            '& .MuiTypography-root': {
                color: COLORS.secondory
            },
            '& .MuiSvgIcon-root': {
                color: COLORS.secondory
            }
        }
    }));

    const StyledTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 500,
        color: '#2f3c45'
    }));

    return (
        <Navbar>
            <div className="container" style={{ paddingTop: 30, paddingBottom: 30 }}>
                <Typography variant="h5" fontWeight={'600'} sx={{ color: COLORS.black, textAlign: 'center' }}>
                    <span style={{ color: COLORS.secondory }}>PerfectPay</span>Rent Tenant Service FAQs
                </Typography>

                <Box sx={{ mt: 2, mx: { lg: 7, md: 3, sm: 2, xs: 0 } }}>
                    {faq?.map((item, index) => (
                        <Accordion
                            sx={{ boxShadow: 'none', py: 0.7 }}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            key={index}
                        >
                            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                <StyledTypography>{item?.question}</StyledTypography>
                            </StyledAccordionSummary>
                            <AccordionDetails sx={{ fontSize: 14, lineHeight: 1.5, color: '#2f3c45' }}>{item?.answer}</AccordionDetails>
                        </Accordion>
                    ))}
                </Box>

                <Box sx={{ mt: 5, p: 2, mx: { lg: 8, md: 4, sm: 3, xs: 1 }}}>
                    <Typography variant="subtitle2">Notice and Disclaimer*</Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: '400' }}>
                        The information provided in this FAQ is for informational purposes only. If there are any discrepancies between the
                        content of the FAQ and the terms and conditions outlined in the Tenant Services Agreement, the latter shall prevail.
                        The Tenant Services Agreement governs the relationship between the parties, and any conflicting information in the
                        FAQ is superseded by the specific terms and conditions outlined in the agreement.
                    </Typography>
                </Box>
            </div>
            <LandingPageFooter/>
        </Navbar>
    );
}

export default FAQ;
