import React, { useState } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, MenuItem, Select, FormControl, Grid, Typography, Divider, IconButton, Box } from '@mui/material';
import { COLORS } from '../../../../utils/constants';
import CustomCheckbox from '../../../../components/form/checkbox/CustomCheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GradientButton from '../../../../components/form/button/GradientButton';
import { useSelector } from 'react-redux';
import { apiRequest } from '../../../../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Validation Schema
const validationSchema = Yup.object().shape({
    emailGeneralNotification: Yup.boolean(),
    emailBiddingNotification: Yup.boolean(),
    emailFavoriteNotification: Yup.boolean(),
    emailTimeWatchNotification: Yup.boolean(),
    general: Yup.object().shape({
        receiveUpdates: Yup.boolean(),
        receiveNewFeaturedProperties: Yup.boolean()
    }),
    bidding: Yup.object().shape({
        notifyWinningBid: Yup.boolean(),
        notifyOutbid: Yup.boolean(),
        notifySecuredNow: Yup.boolean(),
        sendTimeWatch: Yup.boolean()
    }),
    favorites: Yup.object().shape({
        notifyBidOn: Yup.boolean(),
        notifyNoLongerAvailable: Yup.boolean(),
        sendTimeWatch: Yup.boolean()
    })
    // timeWatch: Yup.object().shape({
    //     notificationTimes: Yup.array().of(
    //         Yup.object().shape({
    //             duration: Yup.number().required('Value is required'),
    //             unit: Yup.string().oneOf(['minute(s)', 'hour(s)', 'day(s)']).required('Unit is required')
    //         })
    //     )
    // })
});

// Utility functions for conversion
const convertToMinutes = (value, unit) => {
    switch (unit) {
        case 'minute(s)':
            return value;
        case 'hour(s)':
            return value * 60;
        case 'day(s)':
            return value * 1440;
        default:
            return value;
    }
};

const NotifSettings = ({ userPref, getUserPref }) => {
    let { user } = useSelector((state) => state?.user);
    const [unit, setUnit] = useState('hour(s)');
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = async (values) => {
        setIsLoading(true);
        try {
            const res = await apiRequest('put', `user-preferences/${user?.userId}`, {
                ...values,
                userId: user?.userId
            });

            if (res?.data) {
                getUserPref();
				return toast.success("Notification settings updated!");
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            initialValues={userPref}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const formattedValues = {
                    ...values,
                    timeWatch: {
                        notificationTimes: values.timeWatch.notificationTimes.map((time) => ({
                            ...time,
                            inMinutes: convertToMinutes(time.duration, time.unit)
                        }))
                    }
                };
                handleUpdate(formattedValues);
            }}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2} mt={0}>
                        <Grid item lg={2} mt={1}>
                            <Typography variant="subtitle2">Email</Typography>
                        </Grid>

                        <Grid item container lg={7}>
                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Email me about my General notifications'} name="emailGeneralNotification" />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Email me about my Bidding notifications'} name="emailBiddingNotification" />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Email me about my Favorite notification'} name="emailFavoriteNotification" />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Email me about my Time Watch notifications'} name="emailTimeWatchNotification" />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Divider sx={{ bgcolor: COLORS.primary, mt: 2 }} />
                    </Grid>

                    <Grid container spacing={2} mt={0}>
                        <Grid item lg={2} mt={1}>
                            <Typography variant="subtitle2">General</Typography>
                        </Grid>

                        <Grid item container lg={7}>
                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox
                                    label={'Receive notifications about PerfectPayRentBid updates'}
                                    name="general.receiveUpdates"
                                />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox
                                    label={'Recieve notifications about new featured properties'}
                                    name="general.receiveNewFeaturedProperties"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Divider sx={{ bgcolor: COLORS.primary, mt: 2 }} />
                    </Grid>

                    <Grid container spacing={2} mt={0}>
                        <Grid item lg={2} mt={1}>
                            <Typography variant="subtitle2">Bidding</Typography>
                        </Grid>

                        <Grid item container lg={7}>
                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Notify me when my bid is the winning bid'} name="bidding.notifyWinningBid" />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Notify me if the property has been Secured Now'} name="bidding.notifySecuredNow" />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Notify me when I have been outbid'} name="bidding.notifyOutbid" />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox
                                    label={'Send me Time Watch notifications on properties I bid on'}
                                    name="bidding.sendTimeWatch"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Divider sx={{ bgcolor: COLORS.primary, mt: 2 }} />
                    </Grid>

                    <Grid container spacing={2} mt={0}>
                        <Grid item lg={2} mt={1}>
                            <Typography variant="subtitle2">Favorites</Typography>
                        </Grid>

                        <Grid item container lg={7}>
                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox label={'Notify me when a favorited property is bid on'} name="favorites.notifyBidOn" />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox
                                    label={'Notify me when a favorited property is no longer available'}
                                    name="favorites.notifyNoLongerAvailable"
                                />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <CustomCheckbox
                                    label={'Send me Time Watch notifications on my favorite properties'}
                                    name="favorites.sendTimeWatch"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Divider sx={{ bgcolor: COLORS.primary, mt: 2 }} />
                    </Grid>

                    <Grid container spacing={2} mt={0}>
                        <Grid item lg={2} mt={1}>
                            <Typography variant="subtitle2">Time Watch</Typography>
                        </Grid>

                        <Grid item container lg={7} ml={-0.5}>
                            <Grid item lg={12} sm={12} xs={12} my={-0.5}>
                                <FieldArray name="timeWatch.notificationTimes">
                                    {({ push, remove }) => (
                                        <div>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <IconButton
                                                    onClick={() => {
                                                        if (duration !== 0) {
                                                            push({ duration: duration, unit: unit });
                                                            setDuration(0);
                                                        }
                                                    }}
                                                    size="small"
                                                >
                                                    <AddCircleIcon sx={{ fontSize: 15 }} />
                                                </IconButton>

                                                <Typography fontSize={'14px'}>Notify me </Typography>

                                                <TextField
                                                    value={duration}
                                                    onChange={(e) => setDuration(e?.target?.value)}
                                                    variant="standard"
                                                    size="small"
                                                    type="number"
                                                    inputProps={{ style: { fontSize: '13px' } }}
                                                    sx={{ width: 50 }}
                                                />

                                                <FormControl sx={{ width: 100 }} variant="standard" size="small">
                                                    <Select
                                                        sx={{ fontSize: 13, textTransform: 'none' }}
                                                        value={unit}
                                                        onChange={(e) => setUnit(e?.target?.value)}
                                                    >
                                                        <MenuItem value={'minute(s)'}>minute(s)</MenuItem>
                                                        <MenuItem value={'hour(s)'}>hour(s)</MenuItem>
                                                        <MenuItem value={'day(s)'}>day(s)</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <Typography fontSize={'14px'}>before bidding ends</Typography>
                                            </Box>

                                            {values.timeWatch.notificationTimes.map((time, index) => (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 5 }} key={index}>
                                                    <IconButton onClick={() => remove(index)} size="small">
                                                        <CancelIcon sx={{ fontSize: 15, color: 'red' }} />
                                                    </IconButton>
                                                    <Typography textTransform={'none'} fontSize={'14px'}>
                                                        Notify me {time?.duration} {time?.unit} before bid ends.
                                                    </Typography>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </FieldArray>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Divider sx={{ bgcolor: COLORS.primary, my: 2 }} />

                        <GradientButton loading={isLoading} type="submit" label="Save Changes" sx={{ px: 2 }} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default NotifSettings;
