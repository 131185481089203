import React from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';

function TService() {
    return (
        <SideMenu>
            <div>Service</div>
        </SideMenu>
    );
}

export default TService;
