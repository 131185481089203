// Sidebar.js
import React, { useState, useCallback } from 'react';
import Navbar from '.';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { AVATAR_URL, COLORS } from '../../utils/constants';
import { NavLink, useNavigate } from 'react-router-dom';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from '../../redux/slices/userSlice';

const SideMenu = ({ children }) => {
    let { user } = useSelector((state) => state?.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.clear();
        dispatch(saveUserData(null));
        navigate('/');
    };

    return (
        <Navbar>
            <Grid container height={'100%'}>
                <Grid
                    item
                    bgcolor={COLORS.primary}
                    lg={2.3}
                    md={2}
                    sm={0}
                    sx={{ p: 4, display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }, flexDirection: 'column' }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <img
                            src={`${AVATAR_URL}${user?.avatar}`}
                            style={{ width: 55, height: 55, objectFit: 'cover', backgroundColor: 'gray', borderRadius: 40 }}
                        />

                        <Box>
                            <Typography variant="h6" color={COLORS.white}>
                                Hello, {user?.name?.split(' ')[0]}
                            </Typography>
                            <Typography fontSize={12} color={COLORS.white}>
                                @{user?.userName}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ my: 1.5, bgcolor: COLORS.lightWhite, my: 4 }} />
                    {user?.role == '2109' && (
                        <Box sx={{ flex: 1 }}>
                            <NavBtn icon={''} to={'/t-account'} title={'My Account'} />
                            <NavBtn icon={''} to={'/t-favorites'} title={'My Favorites'} />
                            <NavBtn icon={''} to={'/t-bids'} title={'My Bids'} />
                            <NavBtn icon={''} to={'/properties'} title={'Properties'} />
                            <NavBtn icon={''} to={'/t-service'} title={'My Services'} />
                            <NavBtn icon={''} to={'/t-rental'} title={'My Rental'} />
                            <NavBtn icon={''} to={'/t-payrent'} title={'Pay Rent'} />
                        </Box>
                    )}
                    {user?.role == '2101' && (
                        <Box sx={{ flex: 1 }}>
                            <NavBtn icon={''} to={'/t-account'} title={'My Account'} />
                            <NavBtn icon={''} to={'/ld-properties'} title={'My Properties'} />
                        </Box>
                    )}
                    {user?.role == '2000' && (
                        <Box sx={{ flex: 1 }}>
                            <NavBtn icon={''} to={'/tenant-list'} title={'Tenants'} />
                            <NavBtn icon={''} to={'/landlord-list'} title={'Landlords'} />
                            <NavBtn icon={''} to={'/property-list'} title={'Properties'} />
                        </Box>
                    )}

                    <Divider sx={{ my: 1.5, bgcolor: COLORS.lightWhite }} />

                    <Button onClick={handleLogout} sx={{ color: '#fff', textTransform: 'capitalize', mb: -2 }}>
                        Logout
                    </Button>
                </Grid>

                <Grid item lg={9.7} md={10} xs={12} sm={12}>
                    <main>{children}</main>
                </Grid>
            </Grid>
        </Navbar>
    );
};

export default React.memo(SideMenu);

const NavBtn = ({ icon, to, title }) => {
    return (
        <NavLink to={to} className="link">
            <div className="icon"></div>
            <div className="link_text">
                <p>{title}</p>
            </div>
        </NavLink>
    );
};
