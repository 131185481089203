import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { AVATAR_URL } from '../../../../utils/constants';
import CircularLoader from '../../../../components/loader/CircularLoader';
import ErrorText from '../../../../components/error/ErrorText';
import { formatAmount, formatPhoneNumber } from '../../../../utils/helpers';
import moment from 'moment';
import useFetch from '../../../../hooks/useFetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRequest } from '../../../../utils/api';
import ModalConfirmation from '../../../../components/modal/ModalConfirmation';

function TenantDetail() {
    const { id } = useParams();
    const { data, isLoading, error, refetch } = useFetch(`users/tenant/detail/${id}`);

    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const [reason, setReason] = useState('');

    const updateStatus = async () => {
        setLoading(true);
        try {
            const res = await apiRequest('put', `users/update-status/${id}`, { status, reason });
            if (res?.data) {
                await refetch();
                setOpenConfirmation(false);
                return toast.success('Status updated!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SideMenu>
            {isLoading && <CircularLoader />}
            {error && <ErrorText error={error} />}

            {data && (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, alignItems: 'center' }}>
                        <img
                            alt="profile pic"
                            src={`${AVATAR_URL}${data?.profile?.avatar}`}
                            style={{ width: 90, height: 90, borderRadius: 45, backgroundColor: '#e5e5e5' }}
                        />

                        <Box width={400}>
                            <Box>
                                <FormControl sx={{ minWidth: 170 }} size="small">
                                    <InputLabel id="demo-select-small-label">Status</InputLabel>
                                    <Select
                                        defaultValue={data?.profile?.status}
                                        // value={status}
                                        label="Status"
                                        onChange={(e) => setStatus(e?.target?.value)}
                                    >
                                        <MenuItem value={'1000'}>Processing</MenuItem>
                                        <MenuItem value={'1010'}>Rejected</MenuItem>
                                        <MenuItem value={'1020'}>Approved</MenuItem>
                                    </Select>
                                </FormControl>

                                <Button onClick={() => setOpenConfirmation(true)} variant="contained">
                                    Update Status
                                </Button>
                            </Box>

                            {status == '1010' && (
                                <TextField
                                    multiline
                                    rows={2}
                                    value={reason}
                                    onChange={(e) => setReason(e?.target?.value)}
                                    fullWidth
                                    size="small"
                                    label="Reason of Rejection"
                                />
                            )}
                        </Box>
                    </Box>

                    <Grid container spacing={2} p={3}>
                        <Grid item lg={12} mb={3}>
                            <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 1 }}>Profile Information</Typography>
                            <Box bgcolor={'#f5f5f5'} borderRadius={2} p={2}>
                                <Grid container spacing={1}>
                                    <Grid item lg={3}>
                                        First Name
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.firstName}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Last Name
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.lastName}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Email
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.email}
                                    </Grid>
                                    <Grid item lg={3}>
                                        User Name
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.userName}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Phone Number
                                    </Grid>

                                    <Grid item lg={3}>
                                        {formatPhoneNumber(data?.profile?.phoneNo)}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Date of Birth
                                    </Grid>
                                    <Grid item lg={3}>
                                        {moment(data?.profile?.dateOfBirth).format('MMM DD, YYYY')}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Role
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.role === '2109' && 'Tenant'}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Status
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.status === '1000'
                                            ? 'Processing'
                                            : data?.profile?.status === '1010'
                                            ? 'Rejected'
                                            : 'Approved'}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Email Verified
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.isVerifiedEmail ? 'Yes' : 'No'}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Time Zone
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.profile?.timeZone}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Created On
                                    </Grid>
                                    <Grid item lg={3}>
                                        {moment(data?.profile?.createdOn).format('MMM DD, YYYY')}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item lg={12} mb={3}>
                            <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 1 }}>Other Information</Typography>
                            <Box bgcolor={'#f5f5f5'} borderRadius={2} p={2}>
                                <Grid container spacing={1}>
                                    <Grid item lg={3}>
                                        Identification Type
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.tenant?.identificationType}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Identification Value
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.tenant?.identificationValue}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Employer
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.tenant?.employer}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Employer Contact
                                    </Grid>
                                    <Grid item lg={3}>
                                        {formatPhoneNumber(data?.tenant?.employmentContact)}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Employer Contact Email
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.tenant?.employmentContactEmail}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Employer Contact Phone No
                                    </Grid>
                                    <Grid item lg={3}>
                                        {formatPhoneNumber(data?.tenant?.employmentContactPhoneNo)}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Length of Employment
                                    </Grid>
                                    <Grid item lg={3}>
                                        {moment(data?.tenant?.lengthOfEmployment).format('MMM DD, YYYY')}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Annual Household Income
                                    </Grid>
                                    <Grid item lg={3}>
                                        ${formatAmount(data?.tenant?.annualHouseholdIncome)}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Gurantor
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.tenant?.isGurantor ? 'Yes' : 'No'}
                                    </Grid>
                                    <Grid item lg={3}>
                                        Previous Landlord
                                    </Grid>
                                    <Grid item lg={3}>
                                        {data?.tenant?.isPreviousLandlord ? 'Yes' : 'No'}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        {data?.tenant?.isGurantor && (
                            <Grid item lg={12} mb={3}>
                                <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 1 }}>Gurantor Information</Typography>
                                <Box bgcolor={'#f5f5f5'} borderRadius={2} p={2}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={3}>
                                            First Name
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.gurantor?.firstName}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Last Name
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.gurantor?.lastName}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Email
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.gurantor?.email}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Phone Number
                                        </Grid>
                                        <Grid item lg={3}>
                                            {formatPhoneNumber(data?.gurantor?.phoneNo)}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Employment
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.gurantor?.employment}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Employment Contact
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.gurantor?.employmentContact}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Employment Contact Email
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.gurantor?.employmentContactEmail}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Employment Contact Phone No.
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.gurantor?.employmentContactPhoneNo}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Length of Employment
                                        </Grid>
                                        <Grid item lg={3}>
                                            {moment(data?.gurantor?.lengthOfEmployment)?.format('MMM DD, YYYY')}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Annual Household Income
                                        </Grid>
                                        <Grid item lg={3}>
                                            ${formatAmount(data?.gurantor?.annualHouseholdIncome)}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}

                        {data?.tenant?.isPreviousLandlord && (
                            <Grid item lg={12} mb={3}>
                                <Typography sx={{ fontSize: 18, fontWeight: '600', mb: 1 }}>Previous Landlord</Typography>
                                <Box bgcolor={'#f5f5f5'} borderRadius={2} p={2}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={3}>
                                            Currently Landlord
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.previousLandlord?.isCurrentlyLandlord ? 'Yes' : 'No'}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Name
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.previousLandlord?.name}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Email
                                        </Grid>
                                        <Grid item lg={3}>
                                            {data?.previousLandlord?.email}
                                        </Grid>
                                        <Grid item lg={3}>
                                            Phone Number
                                        </Grid>
                                        <Grid item lg={3}>
                                            {formatPhoneNumber(data?.previousLandlord?.phoneNo)}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}

            <ModalConfirmation
                loading={loading}
                open={openConfirmation}
                header={'Update Status'}
                description={'Are you sure you want to update status of this account?'}
                onClickNo={() => setOpenConfirmation(false)}
                onClickYes={() => updateStatus()}
            />
        </SideMenu>
    );
}

export default TenantDetail;
