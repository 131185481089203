import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { COLORS } from '../../../utils/constants';
import GradientButton from '../../../components/form/button/GradientButton';
import SimpleTextField from '../../../components/form/Textfield/SimpleTextFIeld';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRequest } from '../../../utils/api';

export const EnterOTP = ({ email, setShowComp, setToken }) => {
    const FORM_VALIDATION = Yup.object().shape({
        otpValue: Yup.number()
            .typeError('OTP must be a number')
            .integer('OTP must be an integer')
            .required('OTP is required')
            .min(100000, 'OTP must be at least 6 digits')
            .max(999999, 'OTP must be at most 6 digits')
            .required()
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const res = await apiRequest('post', 'users/forgot-password/verify-otp', { otp: values?.otpValue, email });
            if (res?.data) {
                setShowComp('newPassword');
                setToken(res?.data?.token);
                return;
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Formik
            initialValues={{
                email: ''
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => handleSubmit(values)}
        >
            <Form>
                <Grid item lg={4} sx={{ mt: 4, mb: 2 }}>
                    <Box>
                        <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                            Enter your otp
                        </Typography>
                        <SimpleTextField name="otpValue" />
                    </Box>
                </Grid>

                <Grid item lg={12}>
                    <Divider sx={{ bgcolor: COLORS.primary, my: 1, mb: 2 }} />

                    <GradientButton loading={isLoading} type="submit" label="Submit" />
                </Grid>
            </Form>
        </Formik>
    );
};
