import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../../../../utils/constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import GradientButton from '../../../../components/form/button/GradientButton';
import PasswordField from '../../../../components/form/Textfield/PasswordField';
import { apiRequest } from '../../../../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';


const Security = () => {
    let { user } = useSelector((state) => state?.user);
    const [isLoading, setIsLoading] = useState(false);

    const FORM_VALIDATION = Yup.object().shape({
        currentPassword: Yup.string().min(8, 'Password must be at least 8 characters long').required('Required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[a-zA-Z]/, 'Password must contain letter')
            .matches(/[0-9]/, 'Password must contain number')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain special character')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Required')
    });

    const handleUpdatePassword = async (values) => {
        setIsLoading(true);
        try {
            const res = await apiRequest('put', `users/updatePassword/${user?.userId}`, {
                oldPassword: values?.currentPassword,
                newPassword: values?.password
            });

            if(res?.data?.success){
                return toast.success(res?.data?.message);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            initialValues={{
                currentPassword: '',
                password: '',
                confirmPassword: ''
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => handleUpdatePassword(values)}
        >
            <Form>
                <Grid container spacing={2} mt={3}>
                    <Grid item lg={2} xs={12}>
                        <Typography variant="subtitle2">Change Password</Typography>
                    </Grid>

                    <Grid item container spacing={2} lg={7} sm={12}>
                        <Grid item lg={6} sm={6} xs={12}>
                            <Typography fontSize={'14px'} color={COLORS.lightBlack}>
                                Enter your current password
                            </Typography>
                            <PasswordField name={'currentPassword'} />
                        </Grid>
                        <Box width="100%" />
                        <Grid item lg={6} sm={6} xs={12}>
                            <Typography fontSize={'14px'} color={COLORS.lightBlack}>
                                Enter a new password
                            </Typography>
                            <PasswordField name={'password'} />
                        </Grid>
                        <Box width="100%" />
                        <Grid item lg={6} sm={6} xs={12}>
                            <Typography fontSize={'14px'} color={COLORS.lightBlack}>
                                Confirm your new password
                            </Typography>
                            <PasswordField name={'confirmPassword'} />
                        </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Divider sx={{ bgcolor: COLORS.primary }} />

                        <GradientButton loading={isLoading} type="submit" label="Save Changes" sx={{ mt: 2, px: 2 }} />
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};

export default Security;
