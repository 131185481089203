import React, { useState } from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { COLORS } from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../../utils/helpers';
import ErrorText from '../../../../components/error/ErrorText';
import CircularLoader from '../../../../components/loader/CircularLoader';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRequest } from '../../../../utils/api';

function PropertyList() {
    const { user } = useSelector((state) => state?.user);
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useFetch(`properties?pageNumber=1&pageSize=30&user=${user?.userId}`);
    const { data: feat, isLoading: loadingFeat, error: errorFeat } = useFetch(`featured`);

    const [featured, setFeatured] = useState('');
    const [loading, setLoading] = useState(false);

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const res = await apiRequest('put', `featured/${feat?._id}`, { filter: featured });
            if (res?.data) {
                return toast.success('Showcase updated!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <SideMenu>
            <Box sx={{ padding: '2.5rem', overflowY: 'auto' }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h5" fontWeight={'bold'} color={COLORS.primary}>
                        Properties
                    </Typography>

                    {feat && (
                        <Box>
                            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                                <InputLabel id="demo-select-small-label">Showcase property by</InputLabel>
                                <Select
                                    defaultValue={feat?.filter}
                                    // value={status}
                                    label="Showcase property by"
                                    onChange={(e) => setFeatured(e?.target?.value)}
                                >
                                    <MenuItem value={'latest'}>Latest</MenuItem>
                                    <MenuItem value={'lowest-bid'}>Lowest Bid</MenuItem>
                                    <MenuItem value={'highest-bid'}>Highest Bid</MenuItem>
                                    {/* <MenuItem value={'proximity'}>Proximity</MenuItem> */}
                                </Select>
                            </FormControl>

                            <Button disabled={loading} onClick={() => handleUpdate()} sx={{ mt: 1 }} variant="contained">
                                Update
                            </Button>
                        </Box>
                    )}
                </Box>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Starting Bid</TableCell>
                                <TableCell>Maximum Bid</TableCell>
                                <TableCell>Current Bid</TableCell>
                                <TableCell>Landlord</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Tenant</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <CircularLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {error && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <ErrorText error={error} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!!data &&
                                data?.items?.map((row) => (
                                    <TableRow
                                        key={row?._id}
                                        sx={{ borderBottom: '1px solid black', cursor: 'pointer', '&:hover': { bgcolor: '#f5f5f5' } }}
                                        onClick={() => navigate(`/properties/${row?._id}`)}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row?.title}
                                        </TableCell>
                                        <TableCell>${formatAmount(row?.startingBid || 0)}</TableCell>
                                        <TableCell>${formatAmount(row?.maximumBid || 0)}</TableCell>
                                        <TableCell>${formatAmount(row?.currentBid || 0)}</TableCell>
                                        <TableCell>{row?.user ? `${row?.user?.firstName} ${row?.user?.lastName}` : '—'}</TableCell>
                                        <TableCell>{row?.status}</TableCell>
                                        <TableCell>{row?.tenant ? `${row?.tenant?.firstName} ${row?.tenant?.lastName}` : '—'}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </SideMenu>
    );
}

export default PropertyList;
