import React, { useRef, useState } from 'react';
import './carousel.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PROPERTY_URL } from '../../../utils/constants';

const Carousel = ({ images = [] }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleClick = (image) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    const carouselRef = useRef(null);
    const scrollLeft = () => {
        carouselRef.current.scrollBy({ left: -carouselRef.current.clientWidth, behavior: 'smooth' });
    };

    const scrollRight = () => {
        carouselRef.current.scrollBy({ left: carouselRef.current.clientWidth, behavior: 'smooth' });
    };

    return (
        <div style={{ height: '100%' }}>
            <div className="main-container">
                <div onClick={scrollLeft} className="center">
                    <ArrowBackIosIcon sx={{ color: '#fff' }} />
                </div>

                <div className="img-container" ref={carouselRef}>
                    {images?.map((image, index) => (
                        <div key={index} style={{ width: carouselRef?.current?.clientWidth, maxHeight: 410 }}>
                            <img
                                src={`${PROPERTY_URL}${image}`}
                                alt={`Slide ${index}`}
                                onClick={() => handleClick(`${PROPERTY_URL}${image}`)}
                                width={carouselRef?.current?.clientWidth}
                                height={'100%'}
                                style={{ objectFit: 'cover' }}
                            />
                        </div>
                    ))}
                </div>

                <div onClick={scrollRight} className="center">
                    <ArrowForwardIosIcon sx={{ color: '#fff' }} />
                </div>
            </div>
            {selectedImage && (
                <div className="lightbox" onClick={handleClose}>
                    <img src={selectedImage} alt="Selected" className="lightbox-image" />
                </div>
            )}
        </div>
    );
};

export default Carousel;
