import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { apiRequest } from '../utils/api';
import { saveUserData } from '../redux/slices/userSlice';

function RequireAuth({ allowedRoles }) {
    const location = useLocation();
    const dispatch = useDispatch()
    let { user } = useSelector((state) => state?.user);

    const getUserInfo = async () => {
        try {
            const res = await apiRequest('get', `/users/${user?.userId}`);
            // console.log(res, '000');
            if (res?.data?.isActive == true) {
                dispatch(saveUserData({...user, ...res?.data }));
            } else {
                localStorage.clear();
                dispatch(saveUserData(null));
                window.location.replace('/login');
            }
        } catch (error) {
            // console.log('error ', error);
            localStorage.clear();
            dispatch(saveUserData(null));
            window.location.replace('/login');
        }
    };

    useEffect(() => {
        getUserInfo();
    }, []);

    return user?.role?.find((r) => allowedRoles?.includes(r)) ? (
        <Outlet />
    ) : user?.isLogin && user?.isActive ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
