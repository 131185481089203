import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyDoFXnQVDqZyzETF96Qu1wPu0kQlY_G4rs',
    authDomain: 'perfectpayrent-8b8a8.firebaseapp.com',
    projectId: 'perfectpayrent-8b8a8',
    storageBucket: 'perfectpayrent-8b8a8.appspot.com',
    messagingSenderId: '846278420830',
    appId: '1:846278420830:web:067cf01a70defb540547cf',
    measurementId: 'G-SM66SHC5TY'
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BIzYlaejA65d5Og9Xkeqpm1NX-E6SoteXpoLVW4Vi1YyTvnOncmbHAeze4phTI4BKeGVLUnKllIOhOaA4P6mPV4' })
        .then((currentToken) => {
            if (currentToken) {
                // console.log('Client Token ', currentToken);
                return currentToken;
            } else {
                console.log('No Registeration Token Available');
            }
        })
        .catch((err) => {
            console.log('Error while register token ', err);
        });
};

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log('On Message Payload ', payload);

            resolve(payload);
        });
    });
};
