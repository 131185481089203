import { GOOGLE_MAP_KEY } from '../../../../utils/constants';
import React, { useState, useRef, useCallback, useEffect, meo } from 'react';
import { GoogleMap, LoadScript, Autocomplete, Marker, MarkerF, useJsApiLoader } from '@react-google-maps/api';

const styles = {
    searchField: {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '240px',
        height: '40px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
        outline: 'none',
        textOverflow: 'ellipses',
        position: 'absolute',
        margin: '10px'
    }
};

const LocationPicker = ({ setLocation }) => {
    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [marker, setMarker] = useState({
        lat: -3.745,
        lng: -38.523
    });

    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const onLoadAutocomplete = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete?.getPlace();
            const location = place?.geometry?.location;
            setMarker({ lat: location?.lat(), lng: location?.lng() });
            setLocation({ lat: location?.lat(), lng: location?.lng() });
            map?.panTo(location);
        }
    };

    useEffect(() => {
        if (navigator?.geolocation) {
            navigator?.geolocation?.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setMarker({ lat: latitude, lng: longitude });
                    setLocation({ lat: latitude, lng: longitude });
                },
                (error) => {}
            );
        }
    }, []);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries: ['places']
    });

    return (
        <div style={{ borderRadius: 10, overflow: 'hidden' }}>
            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: 400 }}
                    center={marker}
                    onLoad={onLoad}
                    onClick={(e) => {
                        setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                        setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                    }}
                    onUnmount={onUnmount}
                    zoom={15}
                >
                    <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
                        <input type="text" placeholder="Search a place" style={styles.searchField} />
                    </Autocomplete>
                    {marker && <MarkerF position={marker} />}
                </GoogleMap>
            )}
        </div>
    );
};

export default LocationPicker;
