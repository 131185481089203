import React from 'react';
import SideMenu from '../../../../layout/Navbar/SideMenu';
import { Avatar, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { AVATAR_URL, COLORS } from '../../../../utils/constants';
import useFetch from '../../../../hooks/useFetch';
import { formatPhoneNumber } from '../../../../utils/helpers';
import ErrorText from '../../../../components/error/ErrorText';
import CircularLoader from '../../../../components/loader/CircularLoader';
import { useNavigate } from 'react-router-dom';

function LandlordList() {
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useFetch(`users/landlord`);

    return (
        <SideMenu>
            <Box sx={{ padding: '2.5rem', overflowY: 'auto' }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h5" fontWeight={'bold'} color={COLORS.primary}>
                        Landlords
                    </Typography>
                </Box>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Avatar</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Email Verified</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <CircularLoader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {error && (
                                <TableRow sx={{ width: '100%', height: '50vh' }}>
                                    <TableCell colSpan={10}>
                                        <ErrorText error={error} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!!data &&
                                data?.map((row) => (
                                    <TableRow
                                        onClick={() => navigate(`detail/${row?._id}`)}
                                        key={row?._id}
                                        sx={{ borderBottom: '1px solid black', cursor: 'pointer', '&:hover': { bgcolor: '#f5f5f5' } }}
                                    >
                                        <TableCell>
                                            <Avatar
                                                alt={row?.firstName}
                                                src={`${AVATAR_URL}${row?.avatar}`}
                                                sx={{ width: 25, height: 25 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {row?.firstName} {row?.lastName}
                                        </TableCell>
                                        <TableCell>{row?.email}</TableCell>
                                        <TableCell>{formatPhoneNumber(row?.phoneNo)}</TableCell>
                                        <TableCell>{row?.isVerifiedEmail ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>
                                            {row?.status === '1000'
                                                ? 'Processing'
                                                : row?.status === '1010'
                                                ? 'Rejected'
                                                : row?.status === '1020'
                                                ? 'Approved'
                                                : '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </SideMenu>
    );
}

export default LandlordList;
