import React from 'react';
import { IconButton, InputAdornment, OutlinedInput, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { useField } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordField = ({ name, label, ...otherProps }) => {
    const [field, meta] = useField(name);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();

    return (
        <FormControl variant="outlined" fullWidth error={meta.touched && Boolean(meta.error)} size="small">
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
                {...field}
                {...otherProps}
                id={name}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff sx={{ fontSize: 18 }} /> : <Visibility sx={{ fontSize: 18 }} />}
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
            />
            {meta.touched && meta.error ? <FormHelperText>{meta.error}</FormHelperText> : null}
        </FormControl>
    );
};

export default PasswordField;
