// CustomTabs.js
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const CustomTabs = styled(Tabs)(({ theme }) => ({
    minHeight: 0,
    '& .MuiTabs-indicator': {
        backgroundColor: '#000', // Active tab indicator color
        height: 0
    }
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'capitalize',
    fontWeight: '500',
    fontFamily: 'Lato',
    minHeight: 0, // Remove padding from the Tab
    padding: '6px 12px', // Customize padding
    '&.Mui-selected': {
        color: '#000', // Active tab color
        fontWeight: '700'
    }
}));

export { CustomTabs, CustomTab };
