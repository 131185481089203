import { Box, Typography } from '@mui/material';
import React from 'react';

function ErrorText({ error, sx }) {
    return (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', p: 2, ...sx }}>
            <Typography fontSize={14}>{error?.response?.data?.message || error?.response?.data || error?.message}</Typography>
        </Box>
    );
}

export default ErrorText;
