import { Box, Slider, Typography } from '@mui/material';
import React from 'react';

function valuetext(value) {
    return `${value}°C`;
}

const CustomSlider = ({ label, min, max, value, onChange }) => {
    return (
        <Box sx={{ px: 2 }}>
            <Typography fontSize={10} color={'#fff'}>
                {label}
            </Typography>
            <Typography fontSize={10} color={'#fff'}>
                {value[0]} - {value[1]}{value[1] == max && '+'}
            </Typography>
            <Slider
                size="small"
                min={min}
                max={max}
                sx={{
                    color: '#fff',
                    mt: -0.5,
                    mb: -1,
                    '& .MuiSlider-thumb': {
                        width: 8,
                        height: 8
                    },
                    '& .MuiSlider-rail': {
                        height: 1.5
                    },
                    '& .MuiSlider-track': {
                        height: 1.5
                    }
                }}
                value={value}
                onChange={onChange}
                getAriaValueText={valuetext}
                disableSwap
            />
        </Box>
    );
};

export default CustomSlider;
