import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../utils/constants';
import { formatAmount } from '../../../utils/helpers';

function MarketAnalysis({ startingBid = 0, maximumBid = 0, currentBid = 0 }) {
    let totalDiff = maximumBid - startingBid;
    let currentBidDiff = currentBid - startingBid;

    let percentage = (currentBidDiff / totalDiff) * 100;

    return (
        <div>
            <Typography fontSize={16}>
                <b>Market</b> analysis
            </Typography>
            <Divider sx={{ bgcolor: COLORS.primary, my: 1 }} />

            <Typography fontSize={14}>
                Starting bid: ${formatAmount(startingBid)} | Suggested market rate rent: ${formatAmount((startingBid + maximumBid) / 2)} |
                Maximum Bid: ${formatAmount(maximumBid)}
            </Typography>

            <Box sx={{ width: '100%', height: 50, bgcolor: '#87cad1', my: 2, borderRadius: 30, overflow: 'hidden', mb: 3 }}>
                <Box
                    sx={{
                        height: '100%',
                        width: `${percentage}%`,
                        bgcolor: '#09a5d3',
                        borderRadius: 30,
                        p: 1.6,
                        pl: 4
                    }}
                >
                    <Typography color={'#fff'} width={200}>
                        Current bid: <b>${formatAmount(currentBid)}</b>
                    </Typography>
                </Box>
            </Box>
        </div>
    );
}

export default MarketAnalysis;
