import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Login from './pages/auth/login';
import ForgotPassword from './pages/auth/forgotPassword';
import Register from './pages/auth/register';
import ForTenants from './pages/forTenants';
import FAQ from './pages/faq';
import ForLandlords from './pages/forLandlords';
import AboutUs from './pages/aboutUs';
import Layout from './layout';
import Dashboard from './pages/portal/shared/dashboard';
import Missing from './pages/missing';
import RequireAuth from './components/RequireAuth';
import { useDispatch } from 'react-redux';
import { saveUserData } from './redux/slices/userSlice';
import { jwtDecode } from 'jwt-decode';
import UnAuthorized from './pages/unauthorized';
import TAccount from './pages/portal/tenants/account';
import TFavorites from './pages/portal/tenants/favorites';
import TBids from './pages/portal/tenants/bids';
import TService from './pages/portal/tenants/service';
import TRental from './pages/portal/tenants/rental';
import TPayRent from './pages/portal/tenants/payRent';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { onMessageListener, requestForToken } from './firebase';
import NotificationToast from './components/toast/NotificationToast';
import PropertiesList from './pages/properties/PropertiesList';
import PropertyDetail from './pages/properties/PropertyDetail';
import { ThemeProvider } from '@mui/material';
import theme from './themes';
import Notifications from './pages/portal/shared/notifications';
import AddProperty from './pages/portal/landlord/addProperties';
import LProperties from './pages/portal/landlord/propertyList';
import TenantList from './pages/portal/admin/tenantList';
import LandlordList from './pages/portal/admin/landlordList';
import TenantDetail from './pages/portal/admin/tenantList/TenantDetail';
import LandlordDetail from './pages/portal/admin/landlordList/LandlordDetail';
import PropertyList from './pages/portal/admin/propertyList';

const App = () => {
    const dispatch = useDispatch();
    const [notif, setNotif] = useState(null);

    let token = localStorage.getItem('token');
    if (token) {
        const currentTime = Date.now() / 1000;
        let decoded = jwtDecode(token);

        if (decoded?.exp < currentTime || decoded?.isActive == false) {
            localStorage.clear();
            window.location.replace('/login');
        } else {
            dispatch(saveUserData({ token, isLogin: true, ...decoded }));
        }
    }

    onMessageListener()
        .then((payload) => {
            setNotif(payload.notification);
            toast(
                <NotificationToast
                    title={payload.notification?.title}
                    message={payload.notification?.body}
                    icon={payload.notification?.icon}
                />
            );
        })
        .catch((err) => console.log('onMessageListener err ', err));

    useEffect(() => {}, [notif]);

    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="for-tenants" element={<ForTenants />} />
                    <Route path="faq" element={<FAQ />} />
                    <Route path="for-landlords" element={<ForLandlords />} />
                    <Route path="about-us" element={<AboutUs />} />
                    <Route path="properties" element={<PropertiesList />} />
                    <Route path="properties/:id" element={<PropertyDetail />} />
                    <Route path="unauthorized" element={<UnAuthorized />} />

                    <Route element={<RequireAuth allowedRoles={['2000']} />}>
                        {/* <Route path="dashboard" element={<Dashboard />} /> */}
                        <Route path="tenant-list" element={<TenantList />} />
                        <Route path="landlord-list" element={<LandlordList />} />
                        <Route path="tenant-list/detail/:id" element={<TenantDetail />} />
                        <Route path="landlord-list/detail/:id" element={<LandlordDetail />} />
                        <Route path="property-list" element={<PropertyList />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['2109']} />}>
                        <Route path="t-favorites" element={<TFavorites />} />
                        <Route path="t-bids" element={<TBids />} />
                        <Route path="t-service" element={<TService />} />
                        <Route path="t-rental" element={<TRental />} />
                        <Route path="t-payrent" element={<TPayRent />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['2101']} />}>
                        <Route path="ld-properties" element={<LProperties />} />
                        <Route path="add-property" element={<AddProperty />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['2101', '2109']} />}>
                        <Route path="t-account" element={<TAccount />} />
                        <Route path="notifications" element={<Notifications />} />
                    </Route>

                    <Route path="*" element={<Missing />} />
                </Route>
            </Routes>
        </ThemeProvider>
    );
};

export default App;
