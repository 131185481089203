import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Tab, Tabs, TextField, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../../../utils/constants';
import AccountProfile from './AccountProfile';
import AccountSecurity from './AccountSecurity';
import TenantPreApproval from './TenantPreApproval';
import { Consents } from './Consents';
import { apiRequest } from '../../../../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import ImageButton from '../../../../components/buttons/ImageButton';
import ButtonImage1 from '../../../../assets/images/button-image1.webp';
import ButtonImage2 from '../../../../assets/images/button-image2.webp';

export default function TabBar() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [showRoles, setShowRoles] = useState(true);
    const [selectedRole, setSelectedRole] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);
    const [tabBtns, setTabBtns] = useState([
        { title: 'Account profile', disable: false },
        { title: 'Account security', disable: true },
        { title: 'Tenant pre-approval', disable: true },
        { title: 'Consent', disable: true }
    ]);

    const [accountProfile, setAccountProfile] = useState(null);
    const [accountSecurity, setAccountSecurity] = useState(null);
    const [tenant, setTenant] = useState(null);
    const [consents, setConsents] = useState(null);

    const submitForm = async (values) => {
        if (tabIndex === 0) {
            setAccountProfile(values);
            if (selectedRole === '2101') {
                setTabBtns(tabBtns.map((item, index) => (index === 3 ? { ...item, disable: false } : item)));
                setTabIndex(3);
            } else {
                setTabBtns(tabBtns.map((item, index) => (index === 1 ? { ...item, disable: false } : item)));
                setTabIndex(1);
            }
        } else if (tabIndex === 1) {
            setAccountSecurity(values);
            setTabBtns(tabBtns.map((item, index) => (index === 2 ? { ...item, disable: false } : item)));
            setTabIndex(2);
        } else if (tabIndex === 2) {
            setTenant(values);
            setTabBtns(tabBtns.map((item, index) => (index === 3 ? { ...item, disable: false } : item)));
            setTabIndex(3);
        } else if (tabIndex === 3) {
            setConsents(values);
            await signup(accountProfile, accountSecurity, tenant, values);
        } else {
        }
    };

    const signup = async (acctPro, acctSec, tnt, consent) => {
        setIsLoading(true);
        const timeZone = moment().format('Z');
        try {
            let data = [
                { ...acctPro, role: selectedRole, timeZone: `UTC ${timeZone}`, phoneNo: acctPro?.phoneNumber?.trim().replace(/\D/g, '') },
                acctSec,
                {
                    firstName: tnt?.gurantorFirstName,
                    lastName: tnt?.gurantorLastName,
                    email: tnt?.gurantorEmail,
                    phoneNo: tnt?.gurantorPhoneNo?.trim().replace(/\D/g, ''),
                    employment: tnt?.employment,
                    employmentContact: tnt?.employmentContact?.trim().replace(/\D/g, ''),
                    employmentContactEmail: tnt?.employmentContactEmail,
                    employmentContactPhoneNo: tnt?.employmentContactPhoneNo?.trim().replace(/\D/g, ''),
                    lengthOfEmployment: tnt?.lengthOfEmployment,
                    annualHouseholdIncome: tnt?.annualHouseholdIncome
                },
                {
                    isCurrentlyLandlord: tnt?.isCurrentlyLandlord == 'Yes' ? true : false,
                    name: tnt?.landlordName,
                    email: tnt?.landlordEmail,
                    phoneNo: tnt?.landlordPhoneNo?.trim().replace(/\D/g, '')
                },
                {
                    identificationType: tnt?.identificationType,
                    identificationValue: tnt?.identificationValue,
                    isGurantor: tnt?.isGurantor,
                    isPreviousLandlord: tnt?.isPreviousLandlord,
                    employer: tnt?.tEmployer,
                    employmentContact: tnt?.tEmploymentContact?.trim().replace(/\D/g, ''),
                    employmentContactEmail: tnt?.tEmploymentContactEmail,
                    employmentContactPhoneNo: tnt?.tEmploymentContactPhoneNo?.trim().replace(/\D/g, ''),
                    lengthOfEmployment: tnt?.tLengthOfEmployment,
                    annualHouseholdIncome: tnt?.tAnnualHouseholdIncome
                },
                consent
            ];

            const res = await apiRequest('post', '/users/signup', data);

            if (res?.data) {
                setShowVerifyEmail(true);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onGoBack = () => {
        if (selectedRole === '2101') {
            setTabBtns(tabBtns.map((item, index) => (index === tabIndex ? { ...item, disable: true } : item)));
            setTabIndex((pre) => pre - 3);
        } else {
            setTabBtns(tabBtns.map((item, index) => (index === tabIndex ? { ...item, disable: true } : item)));
            setTabIndex((pre) => pre - 1);
        }
    };

    return (
        <Grid container sx={{ padding: '3rem' }}>
            {showRoles ? (
                <>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h4" fontWeight={'bold'} color={COLORS.primary}>
                            Create your account!
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} p={5} justifyContent={'center'} gap={5}>
                        <ImageButton
                            onClick={() => {
                                setSelectedRole('2101');
                                setShowRoles(false);
                            }}
                            imageUrl={ButtonImage1}
                            label={'I am a Landlord'}
                        />
                        <ImageButton
                            onClick={() => {
                                setSelectedRole('2109');
                                setShowRoles(false);
                            }}
                            imageUrl={ButtonImage2}
                            label={'I am a Tenant'}
                        />
                    </Grid>
                </>
            ) : showVerifyEmail ? (
                <>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h4" fontWeight={'bold'} color={COLORS.primary}>
                            Your account has been created!
                        </Typography>

                        <Typography variant="subtitle2" fontWeight={'400'}>
                            Please check your email and verify your email address.
                        </Typography>
                    </Grid>

                    <Grid item lg={12} xs={12} mt={2}>
                        <Typography color={COLORS.primary} sx={{ fontSize: 18 }}>
                            What happens next?
                        </Typography>

                        <Typography variant="subtitle2" fontWeight={'400'}>
                            We will review and verify your account information within 24 hours. In the meantime you can:
                        </Typography>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h4" fontWeight={'bold'} color={COLORS.primary}>
                            Create your account! {selectedRole == '2101' ? '(Landlord)' : '(Tenant)'}
                        </Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} sx={{ mt: 3 }}>
                        <Box>
                            {selectedRole == '2109' &&
                                tabBtns.map((item, index) => (
                                    <Button
                                        key={item?.title}
                                        disabled={item?.disable}
                                        sx={[styles.tabBtn, { fontWeight: tabIndex == index ? 'bold' : 'regular' }]}
                                    >
                                        {item?.title}
                                    </Button>
                                ))}
                            {selectedRole == '2101' &&
                                tabBtns.map((item, index) => {
                                    if (item?.title === 'Account profile' || item?.title === 'Consent') {
                                        return (
                                            <Button
                                                key={item?.title}
                                                disabled={item?.disable}
                                                sx={[styles.tabBtn, { fontWeight: tabIndex == index ? 'bold' : 'regular' }]}
                                            >
                                                {item?.title}
                                            </Button>
                                        );
                                    }
                                })}
                        </Box>
                        <Divider sx={{ bgcolor: COLORS.primary }} />
                    </Grid>

                    <Grid item lg={12} mt={2}>
                        {tabIndex === 0 ? (
                            <AccountProfile accountProfile={accountProfile} submitProfileForm={submitForm} />
                        ) : tabIndex === 1 ? (
                            <AccountSecurity accountSecurity={accountSecurity} submitSecurityForm={submitForm} goBack={onGoBack} />
                        ) : tabIndex === 2 ? (
                            <TenantPreApproval tenant={tenant} submitTenantForm={submitForm} goBack={onGoBack} />
                        ) : tabIndex === 3 ? (
                            <Consents loading={isLoading} consents={consents} submitConsentsForm={submitForm} goBack={onGoBack} />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
}

const styles = {
    tabBtn: {
        fontSize: 15,
        textTransform: 'capitalize',
        padding: 0,
        color: COLORS.primary,
        fontWeight: '400',
        mr: 3
    }
};
