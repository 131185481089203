import React from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Grid, IconButton, Typography } from '@mui/material';
import { numberToWords } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';

function PropertyHeader({ title, bedrooms = 0, bathrooms = 0, area = 0 }) {
    const navigate = useNavigate();

    return (
        <Grid item container justifyContent={'space-between'} lg={12} sx={12}>
            <Grid item>
                <button onClick={() => navigate(-1)} style={{ border: 0, backgroundColor: '#fff', cursor: 'pointer' }}>
                    {'< Back to Results'}
                </button>
                <Typography sx={{ fontSize: 34, fontWeight: 'bold', color: '#2F3C45' }}>{title}</Typography>
                <Typography mt={-1} sx={{ textTransform: 'capitalize' }}>
                    {numberToWords(bedrooms)} Bedrooms | {numberToWords(bathrooms)} Bathrooms | {area} sq ft
                </Typography>
            </Grid>

            <Grid item>
                <IconButton>
                    <ShareIcon />
                </IconButton>
                <IconButton>
                    <FavoriteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default PropertyHeader;
