import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Autocomplete, Chip, TextField, FormHelperText } from '@mui/material';

const AutoCompleteChip = ({ name, label, size = 'small', list, variant = 'outlined', placeholder = 'Search...' }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (event, value) => {
        setFieldValue(name, value);
    };

    const configAutoComplete = {
        ...field,
        fullWidth: true,
        multiple: true,
        size,
        options: list,
        value: field.value || [],
        onChange: handleChange,
        renderTags: (value, getTagProps) =>
            value.map((option, index) => (
                <Chip
                    size={size}
                    key={option}
                    label={option}
                    {...getTagProps({ index })}
                    color="primary"
                    onDelete={() =>
                        setFieldValue(name, value.filter((amenity) => amenity !== option))
                    }
                />
            )),
        renderInput: (params) => (
            <TextField
                {...params}
                variant={variant}
                label={label}
                placeholder={placeholder}
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
            />
        )
    };

    return (
        <>
            <Autocomplete {...configAutoComplete} />
        </>
    );
};

export default AutoCompleteChip;
