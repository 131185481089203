import React from 'react';
import './style.css';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import { COLORS } from '../../utils/constants';

export default function MainSection() {
    return (
        <div className="main-sec-cont">
            <div className="container col-spacebetween">
                <div>
                    {/* <p className="company-name">
                        PerfectPayRent<span>Bid</span>
                    </p> */}
                    {/* <p className="subtitle">For Renters Wanting a Choice</p> */}
                    <p className="company-name" style={{ fontSize: '35px', fontWeight: '700' }}>
                        PerfectPayRent<span style={{ color: '#2F3C45' }}>Bid - Where Renting is redefined</span>
                    </p>
                    <p className="subtitle" style={{fontSize: 25, marginTop: 15, color: '#656868'}}>Welcome to Empowerment: Bid with Confidence, Skip the Hassle.</p>
                </div>

                <div className="search-box">
                    <p>Search</p>
                    <div style={{ height: 16, width: 1.5, backgroundColor: '#000', marginRight: 10, marginLeft: 10 }} />
                    <input className="search-input" placeholder="Type, Bedroom, Bathroom, Street, City, State" />
                    <SearchIcon sx={{ fontSize: 20 }} />
                </div>
            </div>
        </div>
    );
}
