import { Box, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BASE_URL, COLORS } from '../../../utils/constants';
import GradientButton from '../../../components/form/button/GradientButton';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleTextField from '../../../components/form/Textfield/SimpleTextFIeld';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { apiRequest } from '../../../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { saveUserData } from '../../../redux/slices/userSlice';
import { jwtDecode } from 'jwt-decode';
import { requestForToken } from '../../../firebase';
import { GoogleLogin } from '@react-oauth/google';
import moment from 'moment';

export default function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [fcmToken, setFCMToken] = useState(null);
    const [isRememberMe, setIsRememberMe] = useState(false);

    const FORM_VALIDATION = Yup.object().shape({
        email_username: Yup.string().trim().required('Required'),
        password: Yup.string().trim().required('Required')
    });

    const [isLoading, setIsLoading] = useState(false);

    const getFCMToken = async () => {
        try {
            const fcmToken = await requestForToken();
            setFCMToken(fcmToken);
        } catch (error) {}
    };

    useEffect(() => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                getFCMToken();
                return;
            }
        });
    }, []);

    const handleLogin = async (values) => {
        setIsLoading(true);
        try {
            const res = await apiRequest('post', 'users/signin', values);
            let token = res?.data?.token;

            if (res?.data) {
                await afterSuccess(token);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        const timeZone = moment().format('Z');
        console.log('timeZone ', timeZone);
        const redirectUri = `http://localhost:5000/auth/google?role=${encodeURIComponent('tenant')}&timeZone=${timeZone}`;
        window.location.href = redirectUri;
    };

    useLayoutEffect(() => {
        if (token) {
            afterSuccess(token);
        }
    }, [token]);

    const afterSuccess = async (token) => {
        let decoded = jwtDecode(token);
        if (decoded?.isActive) {
            if (decoded?.isVerifiedEmail) {
                dispatch(saveUserData({ token, isLogin: true, ...decoded }));
                localStorage.setItem('token', token);
                if (fcmToken) {
                    const res = await apiRequest('put', `users/update-fcm/${decoded?.userId}`, { webFcmToken: fcmToken });
                }
                if (decoded?.role[0] === '2109') {
                    const from = location.state?.from?.pathname || '/properties';
                    navigate(from, { replace: true });
                    // window.location.replace(from);
                }
                if (decoded?.role[0] === '2101') {
                    const from = location.state?.from?.pathname || '/ld-properties';
                    navigate(from, { replace: true });
                }
                if (decoded?.role[0] == '2000') {
                    navigate('/tenant-list', { replace: true });
                }
            } else {
                return toast.error('Please verify your email then continue login');
            }
        } else {
            return toast.error('Your account is deactivated');
        }
    };

    return (
        <Formik
            initialValues={{
                email_username: '',
                password: ''
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => handleLogin(values)}
        >
            <Form>
                <Grid container sx={{ padding: '3rem' }}>
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                        <Typography variant="h4" fontWeight={'bold'} color={COLORS.primary}>
                            Log in
                        </Typography>
                        <Typography variant="h6" color={COLORS.primary} sx={{ mt: 1 }}>
                            Enter your log in credentials below
                        </Typography>

                        <Divider sx={{ bgcolor: COLORS.primary }} />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12} sx={{ mt: 4 }}>
                        <Box>
                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                Enter your email or username
                            </Typography>
                            <SimpleTextField name={'email_username'} />
                        </Box>

                        <Box sx={{ my: 2 }}>
                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                Enter your password
                            </Typography>
                            <SimpleTextField name={'password'} />
                        </Box>

                        <FormControlLabel
                            control={
                                <Checkbox size="medium" checked={isRememberMe} onChange={(e) => setIsRememberMe(e?.target?.checked)} />
                            }
                            label={'Remember me'}
                        />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider sx={{ bgcolor: COLORS.primary, my: 1, mb: 2 }} />

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <GradientButton loading={isLoading} type="submit" label="Log in" />

                            <Typography
                                onClick={() => navigate('/forgot-password')}
                                sx={{
                                    ml: 2,
                                    transition: '0.4s',
                                    '&:hover': { borderBottom: '1px solid', cursor: 'pointer', transition: '0.4s' }
                                }}
                            >
                                Forget your password?
                            </Typography>
                        </Box>

                        {/* <GoogleLogin onSuccess={handleGoogleSuccess} onFailure={handleGoogleFailure} /> */}
                        {/* <button onClick={handleGoogleLogin}>Sign In with Google</button> */}
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
}
