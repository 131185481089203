import React from 'react';

const GradientButton = ({ title, onClick, bgColor = '#0c3455', isGradient, style }) => {
    const buttonStyle = {
        border: 0,
        padding: '10px 20px',
        // borderRadius: '5px',
        color: 'white',
        fontSize: '12px',
        cursor: 'pointer',
        ...(isGradient ? { background: 'linear-gradient(-45deg, #00a593, #00a7df)' } : { backgroundColor: bgColor }),
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
        ...style
    };

    return (
        <button onClick={onClick} style={buttonStyle} className="button">
            {title}
        </button>
    );
};

export default GradientButton;
