import { Box, Divider, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../../../../utils/constants';
import SimpleTextField from '../../../../components/form/Textfield/SimpleTextFIeld';
import { Field, Form, Formik } from 'formik';
import isEmpty, { customEmailValidation } from '../../../../utils/helpers';
import * as Yup from 'yup';
import GradientButton from '../../../../components/form/button/GradientButton';
import SelectWrapper from '../../../../components/form/Select';
import MaskedInput from '../../../../components/form/Textfield/MaskedInput';
import { apiRequest } from '../../../../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from '../../../../redux/slices/userSlice';

export default function PreApproval({ tenant, getTenant }) {
    let { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch()
    const [isGurantor, setIsGurantor] = useState(tenant?.isGurantor || false);
    const [isPreviousLandlord, setIsPreviousLandlord] = useState(tenant?.isPreviousLandlord || false);

    const [isLoading, setIsLoading] = useState(false);

    const typeOfIdent = [
        { title: 'Passport', inputFieldTitle: 'Passport ID' },
        { title: 'Driving License', inputFieldTitle: 'Driver’s license number' }
    ];

    let GURANTOR_VALIDATION = {
        gurantorFirstName: Yup.string().trim().required('Required'),
        gurantorLastName: Yup.string().trim().required('Required'),
        gurantorEmail: Yup.string()
            .required('Required')
            .test('is-valid-email', 'Email is invalid', (value) => customEmailValidation(value)),
        gurantorPhoneNo: Yup.string().required('Required'),
        employment: Yup.string().trim().required('Required'),
        employmentContact: Yup.string().required('Required'),
        employmentContactEmail: Yup.string()
            .required('Required')
            .test('is-valid-email', 'Email is invalid', (value) => customEmailValidation(value)),
        employmentContactPhoneNo: Yup.string().required('Required'),
        lengthOfEmployment: Yup.date().required('Required'),
        annualHouseholdIncome: Yup.number().typeError('Income must be in integer').required('Required')
    };

    let PRE_LANLORD_VALIDATION = {
        landlordName: Yup.string().trim().required('Required'),
        isCurrentlyLandlord: Yup.string().trim().required('Required'),
        landlordEmail: Yup.string()
            .required('Required')
            .test('is-valid-email', 'Email is invalid', (value) => customEmailValidation(value)),
        landlordPhoneNo: Yup.string().required('Required')
    };

    const handleUpdatePreTenant = async (values) => {
        setIsLoading(true);
        try {
            const res = await apiRequest('put', `tenants/${tenant?.tenantId}`, {
                ...values,
                isCurrentlyLandlord: values?.isCurrentlyLandlord == 'Yes' ? true : false,
                gurantorId: tenant?.gurantorId,
                previousLandlordId: tenant?.previousLandlordId,
                tenantId: tenant?.tenantId,
                userId: user?.userId
            });

            if (res?.data?.success) {
                getTenant();
                dispatch(saveUserData({ ...user, status: '1000' }));
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            initialValues={
                tenant || {
                    identificationType: '',
                    identificationValue: '',
                    tEmployer: '',
                    tEmploymentContact: '',
                    tEmploymentContactEmail: '',
                    tEmploymentContactPhoneNo: '',
                    tLengthOfEmployment: '',
                    tAnnualHouseholdIncome: '',
                    gurantorFirstName: '',
                    gurantorLastName: '',
                    gurantorEmail: '',
                    gurantorPhoneNo: '',
                    employment: '',
                    employmentContact: '',
                    employmentContactEmail: '',
                    employmentContactPhoneNo: '',
                    lengthOfEmployment: '',
                    annualHouseholdIncome: '',
                    isCurrentlyLandlord: '',
                    landlordName: '',
                    landlordEmail: '',
                    landlordPhoneNo: ''
                }
            }
            validationSchema={Yup.object().shape({
                identificationType: Yup.string().trim().required('Required'),
                identificationValue: Yup.string().trim().required('Required'),
                tEmployer: Yup.string().trim().required('Required'),
                tEmploymentContact: Yup.string().required('Required'),
                tEmploymentContactEmail: Yup.string()
                    .required('Required')
                    .test('is-valid-email', 'Email is invalid', (value) => customEmailValidation(value)),
                tEmploymentContactPhoneNo: Yup.string().required('Required'),
                tLengthOfEmployment: Yup.date().required('Required'),
                tAnnualHouseholdIncome: Yup.number().typeError('Income must be in integer').required('Required'),
                ...(!!isGurantor ? GURANTOR_VALIDATION : {}),
                ...(!!isPreviousLandlord ? PRE_LANLORD_VALIDATION : {})
            })}
            onSubmit={(values, { resetForm }) =>
                handleUpdatePreTenant({
                    ...values,
                    isPreviousLandlord,
                    isGurantor
                })
            }
        >
            {({ values }) => (
                <Form>
                    <Grid container>
                        <Grid item container spacing={2}>
                            <Grid item lg={12}>
                                <Typography sx={{ fontSize: 12, fontWeight: '400', my: 2 }}>
                                    Notice: Any saved changes to the information on this page will require your account to become
                                    re-approved. During the re-approval period, your ability to bid will be suspended from bidding and any
                                    current bids will be terminated.
                                </Typography>

                                <Divider sx={{ bgcolor: COLORS.primary }} />
                            </Grid>

                            <Grid item lg={2}>
                                <Typography variant="subtitle2">Identification*</Typography>
                            </Grid>

                            <Grid item container spacing={2} lg={7}>
                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Type of identification
                                    </Typography>
                                    <SelectWrapper
                                        defaultValue={values?.identificationType?.title}
                                        name="identificationType"
                                        options={typeOfIdent}
                                    />
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    {!isEmpty(values?.identificationType) && (
                                        <>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                {values?.identificationType == 'Passport' ? 'Passport ID' : 'Driver’s license number'}
                                            </Typography>
                                            {values?.identificationType == 'Passport' ? (
                                                <Field name="identificationValue" mask="aa999999" component={MaskedInput} />
                                            ) : (
                                                <Field name="identificationValue" mask="a9999-99999-99999" component={MaskedInput} />
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <Divider sx={{ bgcolor: COLORS.primary, my: 2.5 }} />
                        </Grid>

                        <Grid item container spacing={2}>
                            <Grid item lg={2}>
                                <Typography variant="subtitle2">Rental Information</Typography>
                            </Grid>

                            <Grid item container spacing={2} lg={7}>
                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Do you require a Guarantor
                                    </Typography>

                                    <FormControl fullWidth size="small">
                                        <Select value={isGurantor} onChange={(e) => setIsGurantor(e?.target?.value)}>
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Do you have a previous landlord
                                    </Typography>

                                    <FormControl fullWidth size="small">
                                        <Select value={isPreviousLandlord} onChange={(e) => setIsPreviousLandlord(e?.target?.value)}>
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <Divider sx={{ bgcolor: COLORS.primary, my: 2.5 }} />
                        </Grid>

                        <Grid item container spacing={2}>
                            <Grid item lg={2}>
                                <Typography variant="subtitle2">Employment Information</Typography>
                            </Grid>

                            <Grid item container spacing={2} lg={7}>
                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Employer
                                    </Typography>
                                    <SimpleTextField name="tEmployer" />
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Employment contact
                                    </Typography>
                                    <Field name="tEmploymentContact" mask="(999) 999-9999" component={MaskedInput} />
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Employment contact email
                                    </Typography>
                                    <SimpleTextField name="tEmploymentContactEmail" />
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Employment contact phone number
                                    </Typography>
                                    <Field name="tEmploymentContactPhoneNo" mask="(999) 999-9999" component={MaskedInput} />
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Length of employment (date started)
                                    </Typography>
                                    <SimpleTextField
                                        type="date"
                                        name="tLengthOfEmployment"
                                        InputProps={{
                                            inputProps: {
                                                max: new Date().toLocaleDateString('en-CA')
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={6} sm={6} xs={12}>
                                    <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                        Annual household income
                                    </Typography>
                                    <SimpleTextField placeholder="$" name="tAnnualHouseholdIncome" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {isGurantor && (
                            <>
                                <Grid item lg={12} sm={12} xs={12}>
                                    <Divider sx={{ bgcolor: COLORS.primary, my: 2.5 }} />
                                </Grid>

                                <Grid item container spacing={2}>
                                    <Grid item lg={2}>
                                        <Typography variant="subtitle2">Guarantor’s Information</Typography>
                                    </Grid>

                                    <Grid item container spacing={2} lg={7}>
                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Guarantor’s first name
                                            </Typography>
                                            <SimpleTextField name="gurantorFirstName" />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Guarantor’s last name
                                            </Typography>
                                            <SimpleTextField name="gurantorLastName" />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Guarantor’s email address
                                            </Typography>
                                            <SimpleTextField name="gurantorEmail" />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Guarantor’s phone number
                                            </Typography>
                                            {/* <SimpleTextField name="gurantorPhoneNo" /> */}
                                            <Field name="gurantorPhoneNo" mask="(999) 999-9999" component={MaskedInput} />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Employment
                                            </Typography>
                                            <SimpleTextField name="employment" />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Employment contact
                                            </Typography>
                                            {/* <SimpleTextField name="employmentContact" /> */}
                                            <Field name="employmentContact" mask="(999) 999-9999" component={MaskedInput} />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Employment contact email
                                            </Typography>
                                            <SimpleTextField name="employmentContactEmail" />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Employment contact phone number
                                            </Typography>
                                            <Field name="employmentContactPhoneNo" mask="(999) 999-9999" component={MaskedInput} />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Length of employment (date started)
                                            </Typography>
                                            <SimpleTextField
                                                type="date"
                                                name="lengthOfEmployment"
                                                InputProps={{
                                                    inputProps: {
                                                        max: new Date().toLocaleDateString('en-CA')
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Guarantor’s annual household income
                                            </Typography>
                                            <SimpleTextField placeholder="$" name="annualHouseholdIncome" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {isPreviousLandlord && (
                            <>
                                <Grid item lg={12} sm={12} xs={12}>
                                    <Divider sx={{ bgcolor: COLORS.primary, my: 2.5 }} />
                                </Grid>

                                <Grid item container spacing={2}>
                                    <Grid item lg={2}>
                                        <Typography variant="subtitle2">Previous Landlord</Typography>
                                    </Grid>

                                    <Grid item container spacing={2} lg={7}>
                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Are they currently your landlord?
                                            </Typography>
                                            <SelectWrapper name="isCurrentlyLandlord" options={['Yes', 'No']} />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Landlord’s name
                                            </Typography>
                                            <SimpleTextField name="landlordName" />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Landlord’s email
                                            </Typography>
                                            <SimpleTextField name="landlordEmail" />
                                        </Grid>

                                        <Grid item lg={6} sm={6} xs={12}>
                                            <Typography fontSize={'14px'} fontWeight={'400'} color={COLORS.lightBlack}>
                                                Landlord’s phone number
                                            </Typography>
                                            <Field name="landlordPhoneNo" mask="(999) 999-9999" component={MaskedInput} />
                                            {/* <SimpleTextField name="landlordPhoneNo" /> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <Grid item lg={12} sm={12} xs={12}>
                            <Divider sx={{ bgcolor: COLORS.primary, my: 2 }} />

                            <GradientButton loading={isLoading} type="submit" label="Save Changes" sx={{ px: 2 }} />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
