// hooks/useQueryParams.js
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useQueryParams = (initialParams) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [params, setParams] = useState(() => {
        const initialState = {};
        for (const key of Object.keys(initialParams)) {
            initialState[key] = queryParams.get(key) || initialParams[key];
        }
        return initialState;
    });

    useEffect(() => {
        const updatedParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            if (value !== initialParams[key]) {
                updatedParams.set(key, value);
            }
        }
        navigate({ search: updatedParams.toString() });
    }, [params, navigate, initialParams]);

    return [params, setParams];
};

export default useQueryParams;
